import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InfoICircleOutlined } from "@common-packages/react-icons";
import { useTranslation } from "react-i18next";
import { Grid } from "@components/Layout";
import { ProgressCard } from "@components/ProgressCard/ProgressCard";
import Spinner from "@components/Spinner/Spinner";
import { breakpoints } from "@constants";
import { mapAirlineIataToName } from "@mappings";
import { useMediaQuery } from "@hooks";
import "./OnTimePerformanceOverview.scss";
import useRegularityAndPunctualityPeriodData from "../hooks/useRegularityAndPunctualityPeriodData";
export const OntimePerformanceOverview = () => {
    const { data: performancOverview, isLoading } = useRegularityAndPunctualityPeriodData({ direction: "D" });
    const { t } = useTranslation();
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    const airlinesPerformance = (performancOverview === null || performancOverview === void 0 ? void 0 : performancOverview.airlines) || [];
    const sortedAirlinesPerformance = [...airlinesPerformance].sort((a, b) => (a.airlineIata > b.airlineIata ? 1 : -1));
    const getDescriptionText = (completed, planned) => {
        if (completed !== undefined && planned !== undefined) {
            return t("common.progressStatusCompletedAndPlannedOverview", {
                completed,
                planned,
            });
        }
        else if (completed !== undefined) {
            return t("common.progressStatusCompletedOverview", { completed });
        }
        else if (planned !== undefined) {
            return t("common.progressStatusPlannedOverview", { planned });
        }
        else {
            return "";
        }
    };
    if (isLoading)
        return _jsx(Spinner, {});
    return (_jsx(Grid, { columns: isMobile ? 2 : sortedAirlinesPerformance.length || 4, gap: isMobile ? 0.5 : 1, style: { marginBottom: "1rem" }, icon: {
            component: (_jsx(InfoICircleOutlined, { width: "1rem", height: "1rem" })),
            tooltip: {
                id: "punctualityTotalOverviewWidget",
                html: `<h3>${t("onTimePerformance.punctuality.widgets.stats.title")}</h3><p>${t("onTimePerformance.punctuality.widgets.stats.explanation")}</p>`,
            },
        }, children: sortedAirlinesPerformance.map((airline) => {
            return (_jsxs("div", { children: [_jsx("h4", { className: "ontimeperformance-overview__airlinetitle ", children: mapAirlineIataToName(airline.airlineIata, () => {
                            return t(airline.airlineIata);
                        }) }), _jsx("div", { className: "ontimeperformance-overview__card", children: _jsx(ProgressCard, { subtitle: t("onTimePerformance.punctuality.widgets.perAirline.title"), value: airline.airlinePunctuality, description: getDescriptionText(airline.operatedFlights, airline.flightsTimePeriod) }) }), _jsx("div", { className: "ontimeperformance-overview__card", children: _jsx(ProgressCard, { subtitle: t("onTimePerformance.regularity.widgets.perAirline.title"), value: airline.airlineRegularity, description: getDescriptionText(airline.operatedFlights, airline.flightsTimePeriod) }, airline.airlineIata) }), _jsx("div", { className: "ontimeperformance-overview__card", style: { marginBottom: 0 }, children: _jsx(ProgressCard, { variant: "plain", progressVisibility: "hidden", subtitle: t("onTimePerformance.regularity.widgets.stats.cancellations"), value: airline.cancelledFlights }, airline.airlineIata) })] }, airline.airlineIata));
        }) }));
};
