import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./DeIceBays.scss";
import { Chip } from "@digdir/designsystemet-react";
import { Cell } from "@table-library/react-table-library";
import { useTranslation } from "react-i18next";
import { VStack } from "@components/Layout";
import NoDataAvailable from "@components/NoDataAvailable/NoDataAvailable";
import Spinner from "@components/Spinner/Spinner";
import Table from "@components/Table/Table";
import { alignCellLeftClass } from "@components/Table/themes";
import { breakpoints } from "@constants";
import { useDeIceHasChangesContext } from "@features/DeIce/contexts/DeIceHasChangesContext";
import { useMediaQuery } from "@hooks";
const tableColumnHeaders = {
    padNameAcronym: "Pad",
    bayPills: "Bays",
};
const headers = Object.keys(tableColumnHeaders);
const DeIceBays = ({ modifiedPads, setModifiedPads, isLoading, isDisabled }) => {
    const { t } = useTranslation();
    const { setDeIceHasChanges } = useDeIceHasChangesContext();
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.maxWidth})`);
    if (isLoading)
        return _jsx(Spinner, {});
    if (!modifiedPads)
        return _jsx(NoDataAvailable, {});
    const bayNodes = modifiedPads.map(({ bays, nameAcronym, active }, index) => {
        return {
            id: index,
            padNameAcronym: nameAcronym,
            bayPills: bays,
            active: active,
        };
    });
    const updateBay = (padName, bayNumber) => {
        setDeIceHasChanges(true);
        setModifiedPads((prevPads) => prevPads.map((pad) => pad.nameAcronym.toLowerCase() === padName.toLowerCase()
            ? Object.assign(Object.assign({}, pad), { bays: pad.bays.map((bay) => bay.bayNumber === bayNumber ? Object.assign(Object.assign({}, bay), { status: bay.status === 2 ? 1 : 2 }) : bay) }) : pad));
    };
    const getTableColumnHeaders = () => {
        const headerGroups = [
            {
                headers: [
                    {
                        content: tableColumnHeaders.bayPills,
                        key: headers[1],
                        cellProps: {
                            weight: "0.825",
                            className: alignCellLeftClass,
                        },
                    },
                ],
            },
        ];
        if (isMobile) {
            headerGroups[0].headers.unshift({
                content: tableColumnHeaders.padNameAcronym,
                key: headers[0],
                cellProps: {
                    weight: "0.175",
                    className: alignCellLeftClass,
                },
            });
        }
        return headerGroups;
    };
    const customCellRenderers = () => {
        return {
            padNameAcronym: ({ cellIndex, value, row }) => {
                return (_jsx(Cell, { className: "deice-pad-cell" + (!row.active || isDisabled ? " deice-pad-cell-disabled" : ""), children: value }, cellIndex));
            },
            bayPills: ({ cellIndex, className, value, row }) => {
                const bays = value;
                return (_jsx(Cell, { className: className + " deice-bay-cell", children: _jsx(Chip.Group, { size: "sm", className: "deice-bay-cell-group", children: bays.map((bay, index) => {
                            return (_jsx("div", { className: (index + 1) % 3 === 0 ? "deice-bay-cell-pill-with-margin" : "", children: _jsx(Chip.Toggle, { selected: bay.status === 2, checkmark: false, disabled: !row.active || isDisabled, onClick: () => updateBay(row.padNameAcronym, bay.bayNumber), children: bay.bayNumber }) }, index));
                        }) }) }, cellIndex));
            },
        };
    };
    return (_jsxs(VStack, { className: "deice-bays", justify: "start", gap: 1, children: [_jsx("h4", { className: "deice-bays__title", children: t("deice.capacity.controlPanel.bays.title") }), _jsx(Table, { data: { nodes: bayNodes || [] }, headerGroups: getTableColumnHeaders(), cellRenderers: customCellRenderers(), excludeKeys: isMobile ? ["active"] : ["active", "padNameAcronym"], useDesignsystemetTheme: true, style: { minWidth: isMobile ? "auto" : "600px" } })] }));
};
export default DeIceBays;
