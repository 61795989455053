import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PersonGroupFillIcon, PushPinFillIcon, WrenchFillIcon } from "@navikt/aksel-icons";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import usePlanMetadata from "@features/Plan/hooks/usePlanMetadata";
import "./PlanOverviewDisruptionAreaTooltip.scss";
export const PlanOverviewDisruptionTooltip = ({ processTypeName, disruption, id, }) => {
    var _a;
    const { planMetaData } = usePlanMetadata();
    const { t } = useTranslation();
    const cause = (_a = planMetaData === null || planMetaData === void 0 ? void 0 : planMetaData.causesOfDisruptions.find((x) => x.id == disruption.disruptionTypeId)) === null || _a === void 0 ? void 0 : _a.disruptionName;
    return (_jsxs(Tooltip, { id: id, children: [_jsx("h3", { children: disruption.timePeriodFrom + " - " + disruption.timePeriodTo }), _jsx("div", { children: _jsxs("div", { className: "plan-area-tooltip", children: [_jsxs("div", { className: "plan-area-tooltip-info", children: [_jsx("span", { className: "plan-area-tooltip-info-label", children: _jsx("span", { className: disruption.fyi
                                            ? "plan-area-tooltip-info-circle plan-area-tooltip-info-blue"
                                            : disruption.someDisturbances
                                                ? "plan-area-tooltip-info-circle plan-area-tooltip-info-orange"
                                                : "plan-area-tooltip-info-circle plan-area-tooltip-info-red" }) }), _jsxs("span", { className: "plan-area-tooltip-info-name", children: [processTypeName, " "] }), _jsxs("span", { className: "plan-area-tooltip-info-cause", children: [disruption.disruptionTypeId == 1 && (_jsx(WrenchFillIcon, { title: "a11y-title", fontSize: "18px" })), disruption.disruptionTypeId == 2 && (_jsx(PersonGroupFillIcon, { title: "a11y-title", fontSize: "18px" })), disruption.disruptionTypeId == 3 && (_jsx(PushPinFillIcon, { title: "a11y-title", fontSize: "18px" })), t(`plan.create.disruptions.causes.${cause}`)] })] }), _jsx("div", { className: "plan-area-tooltip-comments", children: _jsx("span", { children: disruption.comments }) })] }) })] }));
};
