import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./DeIceCapacityBarChartWidget.scss";
import { InfoICircleOutlined } from "@common-packages/react-icons";
import { useTranslation } from "react-i18next";
import Widget from "@components/Widget/Widget";
import { DeIceCapacityBarChart } from "@charts/apexcharts/DeIce";
import { DeIceCapacityControlPanel } from "../components";
import { DeIceHandlerFilter } from "../components/DeIceCapacityControlPanel/components";
export const DeIceCapacityBarChartWidget = ({ title, subTitle }) => {
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t(title), subtitle: t(subTitle), icon: {
                    component: (_jsx(InfoICircleOutlined, { width: "1rem", height: "1rem" })),
                    tooltip: {
                        id: "deiceCapacityBarChartWidgetTooltip",
                        html: `
                        <div class="deice-capacity-barchart-widget-paragraph">${t("deice.widgetTooltip.flightCapacity")}</div>
                        <div class="deice-capacity-barchart-widget-paragraph">${t("deice.widgetTooltip.filtering")}</div>
                        <div class="deice-capacity-barchart-widget-paragraph">${t("deice.widgetTooltip.flightGraph")}</div>
                        <div class="deice-capacity-barchart-widget-paragraph">${t("deice.widgetTooltip.capacityLineChart")}</div>
                        <div class="deice-capacity-barchart-widget-paragraph">${t("deice.widgetTooltip.capacityExplanation")}</div>
                        <ul>
                            <li class="deice-capacity-barchart-widget-paragraph">
                                - ${" "}${t("deice.widgetTooltip.capacityExplanationBP1")}
                            </li>
                            <li class="deice-capacity-barchart-widget-paragraph">
                                - ${" "}${t("deice.widgetTooltip.capacityExplanationBP2")}
                            </li>
                        </ul>
                        `,
                    },
                } }), _jsxs(Widget.Body, { children: [_jsxs("div", { className: "deice-capacity-barchart-widget-body", children: [_jsx(DeIceHandlerFilter, {}), _jsx(DeIceCapacityControlPanel, {})] }), _jsx(DeIceCapacityBarChart, {})] })] }));
};
DeIceCapacityBarChartWidget.displayName = "DeIceCapacityBarChartWidget";
