import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./WidgetCard.scss";
import classNames from "classnames";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { InfoICircleOutlined } from "@common-packages/react-icons";
import Line from "@components/Layout/Line/Line";
import { AvinorColor } from "@constants";
import Tooltip from "../Tooltip";
const styleSheet = {
    root: classNames("widget-card"),
    header: classNames("widget-card__header"),
    titleIconWrapper: classNames("widget-card__header__title-icon-wrapper"),
    title: classNames("widget-card__header__title-icon-wrapper__title"),
    description: classNames("widget-card__header__description"),
    icon: classNames("widget-card__header__icon"),
    body: classNames("widget-card__body"),
    transition: classNames("widget-card__body__transition"),
    data: classNames("widget-card__body__transition__data"),
    children: classNames("widget-card__body__transition__children"),
    error: classNames("widget-card__body__transition__error"),
    footer: classNames("widget-card__footer"),
    footerLink: classNames("widget-card__footer__link"),
    footerText: classNames("widget-card__footer__text"),
};
export const WidgetCard = ({ title, description, icon, linkText, underline = "none", link, children, footerText, tooltip, error = false, }) => {
    const Icon = icon;
    const { id = "react-tooltip", titles: tooltipTitles, texts: tooltipTexts } = tooltip || {};
    if (error) {
        return (_jsxs("div", { className: styleSheet.root, children: [_jsxs("div", { className: styleSheet.header, children: [_jsxs("div", { className: styleSheet.titleIconWrapper, children: [title && _jsx("span", { className: styleSheet.title, children: title }), Icon && (_jsx("div", { children: _jsx(Icon, { color: AvinorColor.VioletLight400, width: "1.5rem", height: "1.5rem", className: styleSheet.icon }) }))] }), description && _jsx("span", { className: styleSheet.description, children: description })] }), (underline === "header" || underline === "both") && _jsx(Line, {}), _jsx("div", { className: styleSheet.body, children: _jsx("span", { className: styleSheet.error, children: t("noDataAvailable") }) }), (underline === "body" || underline === "both") && _jsx(Line, {}), (linkText || footerText) && (_jsxs("div", { className: styleSheet.footer, children: [footerText && _jsx("div", { className: styleSheet.footerText, children: footerText }), linkText && (_jsx(Link, { className: styleSheet.footerLink, to: `${link}`, children: linkText }))] }))] }));
    }
    return (_jsxs("div", { className: styleSheet.root, children: [_jsxs("div", { className: styleSheet.header, children: [_jsxs("div", { className: styleSheet.titleIconWrapper, children: [title && _jsx("span", { className: styleSheet.title, children: title }), Icon && (_jsx("div", { children: _jsx(Icon, { color: AvinorColor.VioletLight400, width: "1.5rem", height: "1.5rem", className: styleSheet.icon }) })), tooltipTitles && tooltipTexts && (_jsxs(_Fragment, { children: [_jsx(InfoICircleOutlined, { "data-tooltip-id": id, "data-tooltip-html": tooltipTitles === null || tooltipTitles === void 0 ? void 0 : tooltipTitles.map((title, index) => {
                                            return `<h3>${title}</h3><p>${tooltipTexts[index]}</p>`;
                                        }).join("") }), _jsx(Tooltip, { id: id })] }))] }), description && _jsx("span", { className: styleSheet.description, children: description })] }), (underline === "header" || underline === "both") && _jsx(Line, {}), _jsx("div", { className: styleSheet.body, children: children && _jsx("div", { className: styleSheet.children, children: children }) }), (underline === "body" || underline === "both") && _jsx(Line, {}), (linkText || footerText) && (_jsxs("div", { className: styleSheet.footer, children: [footerText && _jsx("div", { className: styleSheet.footerText, children: footerText }), linkText && (_jsx(Link, { className: styleSheet.footerLink, to: `${link}`, children: linkText }))] }))] }));
};
