import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from "@tanstack/react-query";
import ReactApexChart from "react-apexcharts";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { Home, ZoomArea, Move, CirclePlus, CircleMinus } from "@common-packages/react-icons";
import { formatDate, secondsToMinutesRounded } from "@utils/DateUtils";
import NoDataAvailable from "@components/NoDataAvailable/NoDataAvailable";
import { AvinorColor, breakpoints } from "@constants";
import { FormatPattern } from "@enums";
import { getBaggageOnBeltPerformanceAverage, getBaggageOnBeltPerformancePerFlight } from "@features/Baggage/services";
import { defaultApexChartOptions } from "@charts/apexcharts/defaultApexChartOptions";
import Tooltip from "@charts/shared/components/Tooltip/Tooltip";
import { useMediaQuery } from "@hooks";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useGranularityContext } from "@contexts/GranularityContext";
import { useIataContext } from "@contexts/IataContext";
import { useUtcContext } from "@contexts/UtcContext";
import { formatBaggageOnBeltAverageData, formatBaggageOnBeltPerFlightData, getLineAndScatterChartData, } from "./LineAndScatterChartDataUtils";
const LineAndScatterChart = () => {
    const { utc } = useUtcContext();
    const { iata } = useIataContext();
    const { picker: { id, from, to }, } = useDatePickerContext();
    const { granularity } = useGranularityContext({ from, currentPage: id });
    const { t } = useTranslation();
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    const formattedFrom = formatDate(utc, from, FormatPattern.yyyy_MM_dd_HH_mm);
    const formattedTo = formatDate(utc, to, FormatPattern.yyyy_MM_dd_HH_mm);
    const { data: baggageOnBeltPerformanceAverageData } = useQuery({
        queryFn: () => getBaggageOnBeltPerformanceAverage({
            params: {
                iata,
                from: formattedFrom,
                to: formattedTo,
                interval: granularity,
            },
        }),
        queryKey: ["getBaggageOnBeltPerformanceAverage", { from, to, iata, granularity }],
    });
    const { data: baggageOnBeltPerformancePerFlight } = useQuery({
        queryFn: () => getBaggageOnBeltPerformancePerFlight({
            params: {
                iata,
                from: formattedFrom,
                to: formattedTo,
            },
        }),
        queryKey: ["getBaggageOnBeltPerformancePerFlight", { from, to, iata, granularity }],
    });
    if (!baggageOnBeltPerformanceAverageData ||
        !baggageOnBeltPerformanceAverageData.timeInterval ||
        baggageOnBeltPerformanceAverageData.timeInterval.length == 0 ||
        !baggageOnBeltPerformancePerFlight)
        return _jsx(NoDataAvailable, {});
    const baggageOnBeltPerformanceAverageDataFormatted = formatBaggageOnBeltAverageData(baggageOnBeltPerformanceAverageData);
    const baggageOnBeltPerformancePerFlightFormatted = formatBaggageOnBeltPerFlightData(baggageOnBeltPerformancePerFlight);
    const chartData = getLineAndScatterChartData(baggageOnBeltPerformanceAverageDataFormatted, baggageOnBeltPerformancePerFlightFormatted);
    const series = [...chartData.lineSeries, ...chartData.scatteredSeries];
    const xaxisCategories = baggageOnBeltPerformanceAverageDataFormatted.map((baggage) => baggage.date);
    const sharedStyles = {
        colors: AvinorColor.Grey900,
        fontSize: isMobile ? "0.75rem" : "0.875rem",
        fontWeight: "normal",
    };
    const options = {
        colors: series.map((s) => s.stroke),
        chart: {
            toolbar: {
                tools: {
                    download: false,
                    zoom: ReactDOMServer.renderToStaticMarkup(_jsx(ZoomArea, {})),
                    zoomin: ReactDOMServer.renderToStaticMarkup(_jsx(CirclePlus, {})),
                    zoomout: ReactDOMServer.renderToStaticMarkup(_jsx(CircleMinus, {})),
                    pan: ReactDOMServer.renderToStaticMarkup(_jsx(Move, {})),
                    reset: ReactDOMServer.renderToStaticMarkup(_jsx(Home, {})),
                },
            },
            fontFamily: "Roboto, sans-serif",
            type: "scatter",
            zoom: {
                type: "x",
                allowMouseWheelZoom: false,
            },
            animations: {
                enabled: false,
            },
        },
        grid: {
            show: true,
            strokeDashArray: 4,
            xaxis: {
                lines: {
                    show: true,
                },
            },
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: chartData.lineSeries.map((_, index) => index),
            formatter: function (value) {
                if (!value)
                    return "";
                return secondsToMinutesRounded(value);
            },
        },
        stroke: {
            width: 2,
            curve: "smooth",
        },
        fill: {
            type: "solid",
        },
        markers: {
            size: [...chartData.lineSeries.map(() => 0), ...chartData.scatteredSeries.map(() => 6)],
        },
        xaxis: {
            categories: xaxisCategories,
            type: "datetime",
            title: {
                text: t("time"),
                style: sharedStyles,
                offsetY: 5,
            },
            labels: {
                datetimeUTC: utc,
            },
        },
        yaxis: {
            title: {
                text: t("charts.baggage.line.onBeltPerformance.numberOfMinutes"),
                style: sharedStyles,
            },
            labels: {
                formatter: function (bagPerformance) {
                    return `${secondsToMinutesRounded(bagPerformance)}min`;
                },
            },
        },
        tooltip: {
            shared: false,
            intersect: true,
            custom: function ({ seriesIndex, dataPointIndex, w }) {
                var _a, _b;
                const seriesData = (_a = w.config) === null || _a === void 0 ? void 0 : _a.series[seriesIndex];
                if (seriesData) {
                    const flightID = seriesData.data[dataPointIndex].flightID;
                    const airlineName = (_b = baggageOnBeltPerformancePerFlightFormatted.find((x) => x.flights.some((f) => f.flightID == flightID))) === null || _b === void 0 ? void 0 : _b.airlineName;
                    const date = seriesData.data[dataPointIndex].x;
                    const formattedTime = new Date(date).toLocaleTimeString("no-NO", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                        timeZone: utc ? "UTC" : undefined,
                    });
                    const bagPerformance = seriesData.data[dataPointIndex].y;
                    const tooltipElement = (_jsxs(Tooltip, { children: [_jsx(Tooltip.Title, { children: formattedTime }), _jsxs(Tooltip.List, { children: [_jsx(Tooltip.ListItem, { style: { type: "bullet" }, content: { value: t("airline") }, status: { value: airlineName } }, airlineName), _jsx(Tooltip.ListItem, { style: { type: "bullet" }, content: { value: t("flightnumber") }, status: { value: flightID } }, flightID), _jsx(Tooltip.ListItem, { style: { type: "bullet" }, content: { value: t("charts.baggage.baggageperformance") }, status: { value: `${secondsToMinutesRounded(bagPerformance)} min` } }, bagPerformance)] })] }));
                    return ReactDOMServer.renderToStaticMarkup(tooltipElement);
                }
                return ReactDOMServer.renderToStaticMarkup(_jsx(_Fragment, {}));
            },
        },
        legend: defaultApexChartOptions.legend,
        responsive: [
            {
                breakpoint: 768,
                options: {
                    xaxis: {
                        title: {
                            text: undefined,
                        },
                    },
                    yaxis: {
                        title: {
                            text: undefined,
                        },
                        labels: {
                            formatter: function (bagPerformance) {
                                return `${secondsToMinutesRounded(bagPerformance)}min`;
                            },
                        },
                    },
                },
            },
        ],
    };
    return (_jsx(ReactApexChart, { options: options, series: series, height: 600 }));
};
export default LineAndScatterChart;
