import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@tanstack/react-query";
import { Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Grid } from "@components/Layout";
import Line from "@components/Layout/Line/Line";
import { ProgressCard } from "@components/ProgressCard/ProgressCard";
import Spinner from "@components/Spinner/Spinner";
import { breakpoints } from "@constants";
import { useMediaQuery } from "@hooks";
import "./QueueTimeOverview.scss";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useIataContext } from "@contexts/IataContext";
import { getPaxQueueTimes } from "../services";
function formatWaitTimes(seconds) {
    if (seconds === undefined || isNaN(seconds) || seconds < 0) {
        return "-";
    }
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}m ${remainingSeconds}s`;
}
export const QueueTimeOverview = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { picker: { from, to }, } = useDatePickerContext();
    const { t } = useTranslation();
    const { iata } = useIataContext();
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    const { data: queueTimes, isLoading: isLoading } = useQuery({
        queryFn: () => getPaxQueueTimes({
            iata: iata,
            from,
            to,
            interval: 'day',
        }),
        queryKey: ["getPaxQueueTimes", { from, to, iata }],
    });
    const QueueTimeCard = ({ title, qTime, qKpi, minutes }) => (_jsxs(Fragment, { children: [_jsx("h4", { className: "queuetime-overview__title", children: title }), _jsxs(Grid, { columns: 2, gap: isMobile ? 0.5 : 1, children: [_jsx(ProgressCard, { value: qKpi, description: t("pax.widgets.queueTimes.description", { minutes }) }), _jsx(ProgressCard, { variant: "plain", progressVisibility: "hidden", value: qTime, description: t("pax.widgets.queueTimes.queueTimeNow") })] })] }));
    if (isLoading)
        return _jsx(Spinner, {});
    return (_jsxs(Grid, { columns: isMobile ? 1 : 3, gap: isMobile ? 0.5 : 1, children: [_jsx("div", { children: _jsx(QueueTimeCard, { title: t("pax.widgets.queueTimes.security"), qKpi: (queueTimes === null || queueTimes === void 0 ? void 0 : queueTimes.securityKpi) || "-", qTime: formatWaitTimes((_b = (_a = queueTimes === null || queueTimes === void 0 ? void 0 : queueTimes.security) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.count), minutes: "15" }) }), isMobile ? (_jsx("div", { children: _jsx(QueueTimeCard, { title: t("pax.widgets.queueTimes.immigration"), qKpi: (queueTimes === null || queueTimes === void 0 ? void 0 : queueTimes.immigrationKpi) || "-", qTime: formatWaitTimes((_d = (_c = queueTimes === null || queueTimes === void 0 ? void 0 : queueTimes.immigration) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.count), minutes: "30" }) })) : (_jsxs("div", { className: "queuetime-overview__card", children: [_jsx(Line, { vertical: true }), _jsx("div", { className: "queuetime-overview__center", children: _jsx(QueueTimeCard, { title: t("pax.widgets.queueTimes.immigration"), qKpi: (queueTimes === null || queueTimes === void 0 ? void 0 : queueTimes.immigrationKpi) || "-", qTime: formatWaitTimes((_f = (_e = queueTimes === null || queueTimes === void 0 ? void 0 : queueTimes.immigration) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.count), minutes: "30" }) }), _jsx(Line, { vertical: true })] })), _jsx("div", { children: _jsx(QueueTimeCard, { title: t("pax.widgets.queueTimes.emigration"), qKpi: (queueTimes === null || queueTimes === void 0 ? void 0 : queueTimes.emigrationKpi) || "-", qTime: formatWaitTimes((_h = (_g = queueTimes === null || queueTimes === void 0 ? void 0 : queueTimes.emigration) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.count), minutes: "15" }) })] }));
};
