import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
const DeIceWeatherContext = createContext(undefined);
export const DeIceWeatherProvider = ({ children }) => {
    const [deIceWeather, setDeIceWeather] = useState({});
    //This is to be removed later on
    return (_jsx(DeIceWeatherContext.Provider, { value: {
            deIceWeather,
            setDeIceWeather,
        }, children: children }));
};
export const useDeIceWeatherContext = () => {
    const context = useContext(DeIceWeatherContext);
    if (!context) {
        throw new Error("useDeIceWeatherContext must be used within a DeIceWeatherProvider");
    }
    return Object.assign({}, context);
};
