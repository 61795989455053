export var EWeatherCondition;
(function (EWeatherCondition) {
    EWeatherCondition["NO"] = "No Deice";
    EWeatherCondition["FZFG"] = "Freezing Fog [7m 30s]";
    EWeatherCondition["FZDZ"] = "Freezing Drizzle [12m 30s]";
    EWeatherCondition["MSN"] = "Light Snow [12m 30s]";
    EWeatherCondition["DEFAULT"] = "Default [15m]";
    EWeatherCondition["SN"] = "Snow [17m 30s]";
    EWeatherCondition["PSN"] = "Heavy Snow [17m 30s]";
    EWeatherCondition["FZRA"] = "Freezing Rain [19m 30s]";
})(EWeatherCondition || (EWeatherCondition = {}));
export var EDeIceHandler;
(function (EDeIceHandler) {
    EDeIceHandler["SGH"] = "SGH";
    EDeIceHandler["WGH"] = "WGH";
    EDeIceHandler["MENZIES"] = "Menzies";
    EDeIceHandler["MENZIES_SGH"] = "Menzies_SGH";
    EDeIceHandler["OTHER"] = "OTHER";
    EDeIceHandler["ALL"] = "ALL";
})(EDeIceHandler || (EDeIceHandler = {}));
export var EDeIcePad;
(function (EDeIcePad) {
    EDeIcePad["AN"] = "AN";
    EDeIcePad["AS"] = "AS";
})(EDeIcePad || (EDeIcePad = {}));
