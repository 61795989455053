import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./PageLayout.scss";
import { useTranslation } from "react-i18next";
import Button from "@components/Buttons/Button/Button";
import DatePicker from "@components/DatePicker/DatePicker";
import { HStack } from "@components/Layout";
import { WidgetLayout } from "@components/WidgetLayout/WidgetLayout";
import { Page } from "@enums";
import { layout } from "@widgets/registry";
const styleSheet = {
    page: "page",
    page__title: "page__title",
    page__description: "page__description",
    page__date_picker: "page__date-picker",
    page__header: "page__header",
};
const PageLayout = ({ page, customDatePickerProps, children, title, description, widgets, setPunctualityDisplayMode, }) => {
    const { t } = useTranslation();
    const { selectProps, datePickerProps } = customDatePickerProps || {};
    return (_jsxs("section", { className: styleSheet.page, children: [_jsxs("header", { className: styleSheet.page__header, children: [_jsxs("section", { children: [_jsx("h1", { className: styleSheet.page__title, children: title }), description && _jsx("p", { className: styleSheet.page__description, children: description })] }), (customDatePickerProps === null || customDatePickerProps === void 0 ? void 0 : customDatePickerProps.id) && (_jsxs(HStack, { className: styleSheet.page__date_picker, gap: 1, justify: "end", align: "center", wrap: false, children: [page === Page.PunctualityRegularity && (_jsx(Button, { onClick: () => {
                                    if (setPunctualityDisplayMode)
                                        setPunctualityDisplayMode("weeklyOverview");
                                }, variant: "secondary", children: t("datePickerPresets.last5days") })), _jsx(DatePicker, { id: customDatePickerProps.id, selectProps: selectProps, datePickerProps: datePickerProps })] }))] }), widgets && (_jsx(WidgetLayout, { layout: layout, widgets: widgets })), children] }));
};
export default PageLayout;
