import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Cell } from "@table-library/react-table-library";
import { Tooltip } from "react-tooltip";
import { InfoICircleOutlined } from "@common-packages/react-icons";
import "./OnTimePerformanceServiceQualityTableStyles.scss";
export const evaluateAlertIndicatorLevel = (value) => {
    if (value !== 0) {
        if (value < 80)
            return "alarm";
        if (value < 90)
            return "warning";
        return "valid";
    }
    return "invalid";
};
export const evaluateAlertIndicatorLevelInboundTaxiTime = (value) => {
    if (value > 8)
        return "alarm";
    if (value >= 6)
        return "warning";
    return "valid";
};
export const evaluateAlertIndicatorLevelOutboundTaxiTime = (value) => {
    if (value > 14)
        return "alarm";
    if (value >= 12)
        return "warning";
    return "valid";
};
export const evaluateAlertIndicatorLevelStartupDelay = (value) => {
    if (value > 4)
        return "alarm";
    if (value >= 2)
        return "warning";
    return "valid";
};
export const evaluateAlertIndicatorLevelRecoveredDelay = (value) => {
    return value > 0 ? "valid" : "invalid";
};
export const tableColumnHeaders = {
    performanceMetrics: "Performance Metrics",
    value: "Value",
};
export const headers = Object.keys(tableColumnHeaders);
export const headerGroups = () => {
    return [
        {
            headers: [
                {
                    key: headers[0],
                    content: tableColumnHeaders.performanceMetrics,
                    cellProps: {
                        gridColumnStart: 1,
                        gridColumnEnd: 5,
                    },
                },
                {
                    key: headers[1],
                    content: tableColumnHeaders.value,
                    cellProps: {
                        gridColumnStart: 5,
                        gridColumnEnd: 6,
                    },
                },
            ],
        },
    ];
};
export const OnTimePerformanceTimeframeText = ({ text, timeFrame }) => {
    return (_jsxs(_Fragment, { children: [text, " ", _jsxs("span", { className: "OTP-table-timeframe-text", children: ["(", timeFrame, ")"] })] }));
};
export const PerformanceMetricCell = ({ cellIndex, value, gridColumnStart, gridColumnEnd }) => {
    const metric = value;
    return (_jsx(Cell, { gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, className: "OTP-table-performance-cell", children: _jsxs("div", { style: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: [_jsx("span", { children: metric.content }), _jsx(InfoICircleOutlined, { "data-tooltip-id": metric.tooltip.id, "data-tooltip-html": `<h3>${metric.tooltip.title}</h3><p>${metric.tooltip.description}</p>`, "data-tooltip-place": "top", "data-tooltip-position-strategy": "fixed" }), _jsx(Tooltip, { id: metric.tooltip.id })] }) }, cellIndex));
};
