import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PersonGroupFillIcon, WrenchFillIcon } from "@navikt/aksel-icons";
import { useTranslation } from "react-i18next";
import usePlanMetadata from "@features/Plan/hooks/usePlanMetadata";
import "./PlanCreateCalendarEvent.scss";
const getDisruptionTypeAsText = (disruptionId, planMetaData) => {
    const cause = planMetaData === null || planMetaData === void 0 ? void 0 : planMetaData.causesOfDisruptions.find((disruption) => disruption.id === disruptionId);
    if (!cause)
        return "";
    return `plan.create.disruptions.causes.${cause.disruptionName}`;
};
const getDisruptionConsequence = (disruption) => {
    if (disruption.normalOperation)
        return "plan.create.fillInProcess.normalOperation";
    if (disruption.fyi)
        return "plan.create.fillInProcess.fyi";
    if (disruption.someDisturbances)
        return "plan.create.fillInProcess.someDisturbances";
    if (disruption.majorDisturbances)
        return "plan.create.fillInProcess.majorDisturbances";
    else
        return "";
};
export const PlanCreateCalendarEvent = ({ eventInfo }) => {
    const { t } = useTranslation();
    const { planMetaData } = usePlanMetadata();
    const disruption = eventInfo.event.extendedProps;
    if (!disruption || !disruption.timePeriodFrom)
        return _jsx(_Fragment, { children: eventInfo.timeText });
    const disruptionType = t(getDisruptionTypeAsText(disruption === null || disruption === void 0 ? void 0 : disruption.disruptionTypeId, planMetaData));
    const disruptionConsequence = t(getDisruptionConsequence(disruption));
    return (_jsxs("div", { className: "plan-create-calendar-event", children: [_jsxs("div", { className: "plan-create-calendar-event-timelabel", children: [eventInfo.timeText, " | ", _jsx("b", { children: disruptionConsequence }), " |", " ", _jsxs("div", { className: "plan-create-calendar-event-timelabel-disruption", children: [(disruption === null || disruption === void 0 ? void 0 : disruption.disruptionTypeId) == 1 ? (_jsx(WrenchFillIcon, { title: "a11y-title", fontSize: "14px" })) : (_jsx(PersonGroupFillIcon, { title: "a11y-title", fontSize: "14px" })), _jsxs("span", { children: [" ", disruptionType] })] })] }), _jsx("div", { className: "plan-create-calendar-event-comments", children: _jsx("span", { children: disruption.comments }) })] }));
};
