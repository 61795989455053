import { jsx as _jsx } from "react/jsx-runtime";
import { FlytogetIcon, VyIcon, SjNordIcon, TrainRightOutlined } from "@common-packages/react-icons";
const iconMappings = {
    FLT: FlytogetIcon,
    VY: VyIcon,
    SJN: SjNordIcon,
};
const TrainOperatorIcon = ({ operator, height = "1.5rem", width = "1.5rem" }) => {
    const IconComponent = operator && iconMappings[operator] ? iconMappings[operator] : TrainRightOutlined;
    return (_jsx(IconComponent, { height: height, width: width }));
};
export default TrainOperatorIcon;
