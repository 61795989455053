import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ToggleGroup, ToggleGroupRoot } from "@digdir/designsystemet-react";
import "./PlanOverviewDayPickerWidget.scss";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker as ReactDatePicker } from "rsuite";
import Widget from "@components/Widget/Widget";
import { breakpoints } from "@constants";
import { PlanOverviewDay, usePlanDayPickerContext } from "@features/Plan/contexts/PlanDayPickerContext";
import { useMediaQuery } from "@hooks";
export const PlanOverviewDayPickerWidget = () => {
    const { t } = useTranslation();
    const { selectedDay, setSelectedDay, setSelectedDate, planDate } = usePlanDayPickerContext();
    const datePickerRef = useRef(null);
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    const handleOnChange = (date) => {
        if (date) {
            setSelectedDate(date);
        }
    };
    const onExited = () => {
        var _a;
        const inputElement = (_a = datePickerRef.current) === null || _a === void 0 ? void 0 : _a.target;
        inputElement === null || inputElement === void 0 ? void 0 : inputElement.blur();
    };
    return (_jsx(Widget.Container, { children: _jsx(Widget.Body, { children: _jsxs("div", { className: "plan-overview-day-picker-wrapper", children: [_jsxs(ToggleGroupRoot, { value: selectedDay, name: "toggle-group-plan-day", size: "sm", onChange: (e) => setSelectedDay(e), children: [_jsx(ToggleGroup.Item, { value: PlanOverviewDay.YESTERDAY, children: t(PlanOverviewDay.YESTERDAY) }), _jsx(ToggleGroup.Item, { value: PlanOverviewDay.TODAY, children: t(PlanOverviewDay.TODAY) }), _jsx(ToggleGroup.Item, { value: PlanOverviewDay.TOMORROW, children: t(PlanOverviewDay.TOMORROW) })] }), _jsx("div", { className: "plan-overview-day-picker", children: _jsx(ReactDatePicker, { isoWeek: true, ref: datePickerRef, value: planDate, format: "dd.MM.yyyy", onChange: handleOnChange, cleanable: false, oneTap: true, onExited: onExited, placement: isMobile ? "bottomStart" : "bottomEnd" }) })] }) }) }));
};
PlanOverviewDayPickerWidget.displayName = "PlanOverviewDayPickerWidget";
