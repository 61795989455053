import { jsx as _jsx } from "react/jsx-runtime";
import { addDays, subDays } from "date-fns";
import { createContext, useContext, useMemo } from "react";
import { formatDate } from "@utils/DateUtils";
import { FormatPattern } from "@enums";
import { useDatePickerContext } from "@contexts/DatePickerContext";
export var PlanOverviewDay;
(function (PlanOverviewDay) {
    PlanOverviewDay["YESTERDAY"] = "yesterday";
    PlanOverviewDay["TODAY"] = "today";
    PlanOverviewDay["TOMORROW"] = "tomorrow";
    PlanOverviewDay["BEFOREYESTERDAY"] = "beforeYesterday";
    PlanOverviewDay["AFTERTOMORROW"] = "afterTomorrow";
})(PlanOverviewDay || (PlanOverviewDay = {}));
const PlanDayPickerContext = createContext(undefined);
export const PlanDayPickerProvider = ({ children }) => {
    const { setPicker: setSelectedOption, picker } = useDatePickerContext();
    const today = useMemo(() => {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        return date;
    }, []);
    const selectedDay = useMemo(() => {
        const { from } = picker;
        if (!from)
            return PlanOverviewDay.TODAY;
        const normalizedFrom = new Date(from);
        normalizedFrom.setHours(0, 0, 0, 0);
        const yesterday = subDays(today, 1).getTime();
        const tomorrow = addDays(today, 1).getTime();
        if (normalizedFrom.getTime() === today.getTime())
            return PlanOverviewDay.TODAY;
        if (normalizedFrom.getTime() === yesterday)
            return PlanOverviewDay.YESTERDAY;
        if (normalizedFrom.getTime() === tomorrow)
            return PlanOverviewDay.TOMORROW;
        if (normalizedFrom.getTime() < yesterday)
            return PlanOverviewDay.BEFOREYESTERDAY;
        if (normalizedFrom.getTime() > tomorrow)
            return PlanOverviewDay.AFTERTOMORROW;
        return PlanOverviewDay.TODAY; // Default fallback
    }, [picker, today]);
    const planDate = picker.from;
    const planDateFormatted = useMemo(() => formatDate(false, planDate, FormatPattern.yyyy_MM_dd), [planDate]);
    // Boolean values for date comparison
    const dateIsToday = useMemo(() => planDate.getTime() === today.getTime(), [planDate, today]);
    const dateIsPast = useMemo(() => planDate.getTime() < today.getTime(), [planDate, today]);
    const dateIsFuture = useMemo(() => planDate.getTime() > today.getTime(), [planDate, today]);
    const setSelectedDay = (day) => {
        let newDate;
        switch (day) {
            case PlanOverviewDay.TODAY:
                newDate = today;
                break;
            case PlanOverviewDay.YESTERDAY:
                newDate = subDays(today, 1);
                break;
            case PlanOverviewDay.TOMORROW:
                newDate = addDays(today, 1);
                break;
            default:
                console.error("Invalid day selected");
                return;
        }
        setSelectedOption({ from: newDate, to: newDate });
    };
    const setSelectedDate = (date) => {
        const normalizedDate = new Date(date);
        normalizedDate.setHours(0, 0, 0, 0);
        setSelectedOption({ from: normalizedDate, to: normalizedDate });
    };
    return (_jsx(PlanDayPickerContext.Provider, { value: {
            selectedDay,
            planDate,
            planDateFormatted,
            setSelectedDay,
            setSelectedDate,
            dateIsPast,
            dateIsFuture,
            dateIsToday,
        }, children: children }));
};
export const usePlanDayPickerContext = () => {
    const context = useContext(PlanDayPickerContext);
    if (!context) {
        throw new Error("usePlanDayPickerContext must be used within a PlanDayPickerProvider");
    }
    return context;
};
