import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from "react";
import { PlaneDownOutlined } from "@common-packages/react-icons";
import Spinner from "@components/Spinner/Spinner";
import Widget from "@components/Widget/Widget";
import { OnTimePerformanceServiceQualityArrivalTable } from "@features/OnTimePerformance/components";
export const OnTimePerformanceServiceQualityArrivalTableWidget = () => {
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: "Arrival", subtitleVisibility: "none", icon: {
                    component: (_jsx(PlaneDownOutlined, { width: "2em", height: "2em" })),
                } }), _jsx(Suspense, { fallback: _jsx(Spinner, {}), children: _jsx(Widget.Body, { children: _jsx(OnTimePerformanceServiceQualityArrivalTable, {}) }) })] }));
};
OnTimePerformanceServiceQualityArrivalTableWidget.displayName = "OnTimePerformanceServiceQualityArrivalTableWidget";
