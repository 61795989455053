import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Widget from "@components/Widget/Widget";
import { FormatPattern } from "@enums";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useIataContext } from "@contexts/IataContext";
import { DeIceApronHeaterStatus } from "../components/DeIceApronHeaterStatus";
import { getApronHeaterStatus } from "../services";
export const DeIceApronHeaterWidget = () => {
    const { t, i18n } = useTranslation();
    const { iata } = useIataContext();
    const [subTitleText, setSubTitleText] = useState("");
    const { picker: { from }, } = useDatePickerContext();
    const date = format(from, FormatPattern.yyyy_MM_dd);
    const { data: apronHeaterStatusData } = useQuery({
        queryFn: () => getApronHeaterStatus({
            date,
            iata,
        }),
        queryKey: ["apronHeaterStatusData", { date, iata }],
    });
    useEffect(() => {
        if (apronHeaterStatusData && apronHeaterStatusData.length > 0) {
            const latestEntry = apronHeaterStatusData === null || apronHeaterStatusData === void 0 ? void 0 : apronHeaterStatusData.reduce((latest, current) => new Date(current.updatedAt) > new Date(latest.updatedAt) ? current : latest);
            if (latestEntry === null || latestEntry === void 0 ? void 0 : latestEntry.updatedAt) {
                if (latestEntry.apronHeaterStatus) {
                    setSubTitleText(t("deice.apronHeater.turnedOn"));
                }
                else {
                    setSubTitleText(t("deice.apronHeater.turnedOff"));
                }
            }
            else {
                setSubTitleText("");
            }
        }
        else {
            setSubTitleText("");
        }
    }, [apronHeaterStatusData, i18n.language]);
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t("deice.apronHeater.title"), subtitle: subTitleText }), _jsx(Widget.Body, { children: _jsx(DeIceApronHeaterStatus, { date: date }) })] }));
};
DeIceApronHeaterWidget.displayName = "DeIceApronHeaterWidget";
