import { jsx as _jsx } from "react/jsx-runtime";
import { isPast, isToday } from "date-fns";
import { useTranslation } from "react-i18next";
import { getDateToReadableText } from "@utils/DateUtils";
import { Page } from "@enums";
import { paxWidgets } from "@widgets/registry";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useIataContext } from "@contexts/IataContext";
import PageLayout from "./PageLayout/PageLayout";
import { getDatePickerSelectOptionsForPage } from "./utils";
const PaxPage = () => {
    const { iata: airport } = useIataContext();
    const { t } = useTranslation();
    const { picker: { id }, } = useDatePickerContext();
    return (_jsx(PageLayout, { customDatePickerProps: {
            id,
            selectProps: {
                options: getDatePickerSelectOptionsForPage(Page.Pax),
            },
            datePickerProps: {
                shouldDisableDate: (date) => {
                    return !isPast(date) && !isToday(date);
                },
            },
        }, title: `${t("pages.passengers.title")} ${getDateToReadableText().toLowerCase()}`, description: t("pages.passengers.description", { iata: airport }), widgets: paxWidgets }));
};
export default PaxPage;
