import { t } from "i18next";
import * as Icons from "@common-packages/react-icons";
const WEATHER_MAPPINGS = [
    { code: "clearsky_day", description: t("weather.clearSkyDay"), icon: Icons.ClearskyDay },
    { code: "clearsky_night", description: t("weather.clearSkyNight"), icon: Icons.ClearskyNight },
    { code: "cloudy", description: t("weather.cloudy"), icon: Icons.Cloudy },
    { code: "fair_day", description: t("weather.fairDay"), icon: Icons.FairDay },
    { code: "fair_night", description: t("weather.fairNight"), icon: Icons.FairNight },
    { code: "fog", description: t("weather.fog"), icon: Icons.Fog },
    { code: "heavyrain", description: t("weather.heavyRain"), icon: Icons.Heavyrain },
    { code: "heavyrainandthunder", description: t("weather.heavyRainAndThunder"), icon: Icons.Heavyrainandthunder },
    { code: "heavyrainshowers_day", description: t("weather.heavyRainShowersDay"), icon: Icons.HeavyrainshowersDay },
    {
        code: "heavyrainshowers_night",
        description: t("weather.heavyRainShowersNight"),
        icon: Icons.HeavyrainshowersNight,
    },
    {
        code: "heavyrainshowersandthunder_day",
        description: t("weather.heavyRainShowersAndThunderDay"),
        icon: Icons.HeavyrainshowersandthunderDay,
    },
    {
        code: "heavyrainshowersandthunder_night",
        description: t("weather.heavyRainShowersAndThunderNight"),
        icon: Icons.HeavyrainshowersandthunderNight,
    },
    { code: "heavysleet", description: t("weather.heavySleet"), icon: Icons.Heavysleet },
    {
        code: "heavysleetshowersandthunder_day",
        description: t("weather.heavySleetShowersAndThunderDay"),
        icon: Icons.HeavysleetshowersandthunderDay,
    },
    {
        code: "heavysleetshowersandthunder_night",
        description: t("weather.heavySleetShowersAndThunderNight"),
        icon: Icons.HeavysleetshowersandthunderNight,
    },
    { code: "heavysnow", description: t("weather.heavySnow"), icon: Icons.Heavysnow },
    { code: "heavysnowandthunder", description: t("weather.heavySnowAndThunder"), icon: Icons.Heavysnowandthunder },
    { code: "heavysnowshowers_day", description: t("weather.heavySnowShowersDay"), icon: Icons.HeavysnowshowersDay },
    {
        code: "heavysnowshowers_night",
        description: t("weather.heavySnowShowersNight"),
        icon: Icons.HeavysnowshowersNight,
    },
    { code: "lightrain", description: t("weather.lightRain"), icon: Icons.Lightrain },
    { code: "lightrainandthunder", description: t("weather.lightRainAndThunder"), icon: Icons.Lightrainandthunder },
    { code: "lightrainshowers_day", description: t("weather.lightRainShowersDay"), icon: Icons.LightrainshowersDay },
    {
        code: "lightrainshowers_night",
        description: t("weather.lightRainShowersNight"),
        icon: Icons.LightrainshowersNight,
    },
    {
        code: "lightrainshowersandthunder_day",
        description: t("weather.lightRainShowersAndThunderDay"),
        icon: Icons.LightrainshowersandthunderDay,
    },
    {
        code: "lightrainshowersandthunder_night",
        description: t("weather.lightRainShowersAndThunderNight"),
        icon: Icons.LightrainshowersandthunderNight,
    },
    { code: "lightsleet", description: t("weather.lightSleet"), icon: Icons.Lightsleet },
    { code: "lightsnow", description: t("weather.lightSnow"), icon: Icons.Lightsnow },
    { code: "lightsnowandthunder", description: t("weather.lightSnowAndThunder"), icon: Icons.Lightsnowandthunder },
    { code: "lightsnowshowers_day", description: t("weather.lightSnowShowersDay"), icon: Icons.LightsnowshowersDay },
    {
        code: "lightsnowshowers_night",
        description: t("weather.lightSnowShowersNight"),
        icon: Icons.LightsnowshowersNight,
    },
    {
        code: "lightssleetshowersandthunder_day",
        description: t("weather.lightSleetShowersAndThunderDay"),
        icon: Icons.LightssleetshowersandthunderDay,
    },
    {
        code: "lightssleetshowersandthunder_night",
        description: t("weather.lightSleetShowersAndThunderNight"),
        icon: Icons.LightssleetshowersandthunderNight,
    },
    { code: "partlycloudy_day", description: t("weather.partlyCloudyDay"), icon: Icons.PartlycloudyDay },
    { code: "partlycloudy_night", description: t("weather.partlyCloudyNight"), icon: Icons.PartlycloudyNight },
    { code: "rain", description: t("weather.rain"), icon: Icons.Rain },
    { code: "rainandthunder", description: t("weather.rainAndThunder"), icon: Icons.Rainandthunder },
    { code: "rainshowers_day", description: t("weather.rainShowersDay"), icon: Icons.RainshowersDay },
    { code: "rainshowers_night", description: t("weather.rainShowersNight"), icon: Icons.RainshowersNight },
    {
        code: "rainshowersandthunder_day",
        description: t("weather.rainShowersAndThunderDay"),
        icon: Icons.RainshowersandthunderDay,
    },
    {
        code: "rainshowersandthunder_night",
        description: t("weather.rainShowersAndThunderNight"),
        icon: Icons.RainshowersandthunderNight,
    },
    { code: "sleet", description: t("weather.sleet"), icon: Icons.Sleet },
    {
        code: "sleetshowersandthunder_day",
        description: t("weather.sleetShowersAndThunderDay"),
        icon: Icons.SleetshowersandthunderDay,
    },
    {
        code: "sleetshowersandthunder_night",
        description: t("weather.sleetShowersAndThunderNight"),
        icon: Icons.SleetshowersandthunderNight,
    },
    { code: "snow", description: t("weather.snow"), icon: Icons.Snow },
    { code: "snowandthunder", description: t("weather.snowAndThunder"), icon: Icons.Snowandthunder },
    { code: "snowshowers_day", description: t("weather.snowShowersDay"), icon: Icons.SnowshowersDay },
    { code: "snowshowers_night", description: t("weather.snowShowersNight"), icon: Icons.SnowshowersNight },
    { code: "sleetshowers_day", description: t("weather.sleetShowersDay"), icon: Icons.Sleet },
    { code: "sleetshowers_night", description: t("weather.sleetShowersNight"), icon: Icons.Sleet },
    {
        code: "snowshowersandthunder_day",
        description: t("weather.snowShowersAndThunderDay"),
        icon: Icons.Snowandthunder,
    },
    {
        code: "snowshowersandthunder_night",
        description: t("weather.snowShowersAndThunderNight"),
        icon: Icons.Snowandthunder,
    },
    { code: "sleetandthunder", description: t("weather.sleetAndThunder"), icon: Icons.Sleet },
    {
        code: "lightssnowshowersandthunder_day",
        description: t("weather.lightSnowShowersAndThunderDay"),
        icon: Icons.Snowandthunder,
    },
    {
        code: "lightssnowshowersandthunder_night",
        description: t("weather.lightSnowShowersAndThunderNight"),
        icon: Icons.Snowandthunder,
    },
    {
        code: "heavysnowshowersandthunder_day",
        description: t("weather.heavySnowShowersAndThunderDay"),
        icon: Icons.Snowandthunder,
    },
    {
        code: "heavysnowshowersandthunder_night",
        description: t("weather.heavySnowShowersAndThunderNight"),
        icon: Icons.Snowandthunder,
    },
    { code: "lightsleetandthunder", description: t("weather.lightSleetAndThunder"), icon: Icons.Sleet },
    { code: "heavysleetandthunder", description: t("weather.heavySleetAndThunder"), icon: Icons.Sleet },
    { code: "lightsleetshowers_day", description: t("weather.lightSleetShowersDay"), icon: Icons.Sleet },
    { code: "lightsleetshowers_night", description: t("weather.lightSleetShowersNight"), icon: Icons.Sleet },
    { code: "heavysleetshowers_day", description: t("weather.heavySleetShowersDay"), icon: Icons.Sleet },
    { code: "heavysleetshowers_night", description: t("weather.heavySleetShowersNight"), icon: Icons.Sleet },
];
export const mapWeatherCodeToIcon = (weatherCode) => {
    const { icon: Icon, description } = WEATHER_MAPPINGS.find(({ code }) => code === weatherCode) || {
        icon: null,
        description: "",
    };
    const Component = Icon;
    return {
        icon: Component,
        iconDescription: description,
    };
};
