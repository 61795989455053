import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InfoICircleOutlined } from "@common-packages/react-icons";
import { useTranslation } from "react-i18next";
import Widget from "@components/Widget/Widget";
import { QueueTimeOverview } from "../components/QueueTimeOverview";
export const PaxQueueTimeWidget = () => {
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t("pax.widgets.queueTimes.title"), subtitleVisibility: "none", icon: {
                    component: (_jsx(InfoICircleOutlined, { width: "1rem", height: "1rem" })),
                    tooltip: {
                        id: "paxQueueTimeWidget",
                        html: `<h3>${t("pax.widgets.queueTimes.title")}</h3><p>${t("pax.widgets.queueTimes.explanation")}</p>`,
                    },
                } }), _jsx(Widget.Body, { children: _jsx(QueueTimeOverview, {}) })] }));
};
PaxQueueTimeWidget.displayName = "PaxQueueTimeWidget";
