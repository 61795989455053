var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./DeIcePads.scss";
import { Checkbox, Switch } from "@digdir/designsystemet-react";
import { Cell } from "@table-library/react-table-library";
import { useTranslation } from "react-i18next";
import { VStack } from "@components/Layout";
import NoDataAvailable from "@components/NoDataAvailable/NoDataAvailable";
import Spinner from "@components/Spinner/Spinner";
import Table from "@components/Table/Table";
import { alignCellCenterClass } from "@components/Table/themes";
import { useDeIceHasChangesContext } from "@features/DeIce/contexts/DeIceHasChangesContext";
import { EDeIceHandler } from "@features/DeIce/enums";
const tableColumnHeaders = {
    padState: "Active",
    men: "MEN",
    sgh: "SGH",
    wgh: "WGH",
};
const headers = Object.keys(tableColumnHeaders);
const getTableColumnHeaders = () => {
    return [
        {
            headers: [
                {
                    content: tableColumnHeaders.padState,
                    key: headers[0],
                    cellProps: {
                        weight: 1.5,
                    },
                },
                {
                    content: tableColumnHeaders.men,
                    key: headers[1],
                    cellProps: {
                        className: `${alignCellCenterClass}`,
                    },
                },
                {
                    content: tableColumnHeaders.sgh,
                    key: headers[2],
                    cellProps: {
                        className: `${alignCellCenterClass}`,
                    },
                },
                {
                    content: tableColumnHeaders.wgh,
                    key: headers[3],
                    cellProps: {
                        className: `${alignCellCenterClass}`,
                    },
                },
            ],
        },
    ];
};
const DeIcePads = ({ modifiedPads, setModifiedPads, isLoading, isDisabled }) => {
    const { t } = useTranslation();
    const { setDeIceHasChanges } = useDeIceHasChangesContext();
    if (isLoading)
        return _jsx(Spinner, {});
    if (!modifiedPads)
        return _jsx(NoDataAvailable, {});
    const padNodes = modifiedPads.map(({ active, handlers, nameAcronym }, index) => {
        const padState = { name: nameAcronym, active: active };
        return {
            id: index,
            padState: padState,
            men: handlers.some((x) => x.toLowerCase() == EDeIceHandler.MENZIES.toLowerCase()),
            sgh: handlers.some((x) => x.toLowerCase() == EDeIceHandler.SGH.toLowerCase()),
            wgh: handlers.some((x) => x.toLowerCase() == EDeIceHandler.WGH.toLowerCase()),
        };
    });
    const updatePadState = (isActive, padState) => {
        setDeIceHasChanges(true);
        setModifiedPads((prevPads) => {
            return prevPads.map((pad) => {
                if (pad.nameAcronym.toLowerCase() === padState.name.toLowerCase()) {
                    return Object.assign(Object.assign({}, pad), { active: isActive });
                }
                return pad;
            });
        });
    };
    const updatePadHandlers = (isActive, padState, handler) => {
        setDeIceHasChanges(true);
        setModifiedPads((prevPads) => {
            return prevPads.map((pad) => {
                if (pad.nameAcronym.toLowerCase() === padState.name.toLowerCase()) {
                    let updatedHandlers = pad.handlers;
                    if (isActive) {
                        // Add the handler if it's not already in the array
                        if (!pad.handlers.some((x) => x.toLowerCase() === handler.toLowerCase())) {
                            updatedHandlers = [...pad.handlers, handler];
                        }
                    }
                    else {
                        // Remove the handler if it's in the array
                        updatedHandlers = pad.handlers.filter((x) => x.toLowerCase() !== handler.toLowerCase());
                    }
                    return Object.assign(Object.assign({}, pad), { handlers: updatedHandlers });
                }
                return pad;
            });
        });
    };
    const PadHandlerCell = ({ cellIndex, value, className, handler, row }) => {
        return (_jsx(Cell, { className: className + " deice-pad-handler-cell", children: _jsx(Checkbox, { "aria-label": "Checkbox", size: "sm", disabled: !row.padState.active || isDisabled, checked: value, onChange: () => updatePadHandlers(!value, row.padState, handler), value: value }) }, cellIndex));
    };
    const customCellRenderers = () => {
        return {
            padState: ({ cellIndex, value }) => {
                const padState = value;
                return (_jsx(Cell, { children: _jsx("div", { className: "deice-pad-switch", children: _jsx(Switch, { position: "right", size: "sm", checked: padState.active, disabled: isDisabled, onChange: () => updatePadState(!padState.active, padState), children: padState.name }) }) }, cellIndex));
            },
            men: (cellProps) => {
                const { key } = cellProps, propsWithoutKey = __rest(cellProps, ["key"]);
                return (_jsx(PadHandlerCell, Object.assign({ handler: EDeIceHandler.MENZIES }, propsWithoutKey), key));
            },
            sgh: (cellProps) => {
                const { key } = cellProps, propsWithoutKey = __rest(cellProps, ["key"]);
                return (_jsx(PadHandlerCell, Object.assign({ handler: EDeIceHandler.SGH }, propsWithoutKey), key));
            },
            wgh: (cellProps) => {
                const { key } = cellProps, propsWithoutKey = __rest(cellProps, ["key"]);
                return (_jsx(PadHandlerCell, Object.assign({ handler: EDeIceHandler.WGH }, propsWithoutKey), key));
            },
        };
    };
    return (_jsxs(VStack, { className: "deice-pads", justify: "start", gap: 1, children: [_jsx("h4", { className: "deice-pads__title", children: t("deice.capacity.controlPanel.pads.title") }), _jsx(Table, { data: { nodes: padNodes || [] }, headerGroups: getTableColumnHeaders(), cellRenderers: customCellRenderers(), useDesignsystemetTheme: true })] }));
};
export default DeIcePads;
