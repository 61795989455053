import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./DeIceCapacityControlPanel.scss";
import { GearOutlined } from "@common-packages/react-icons";
import clsx from "clsx";
import { useRef, useState } from "react";
import Button from "@components/Buttons/Button/Button";
import { HStack, VStack } from "@components/Layout";
import { DeIceSettingsModal } from "./components/DeIceSettingsModal/DeIceSettingsModal";
const styleSheet = {
    root: (expand) => clsx("deice-capacity-control-panel", {
        "deice-capacity-control-panel--expanded": expand,
    }),
};
export const DeIceCapacityControlPanel = () => {
    const modalRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => {
        setIsModalOpen(true);
        setTimeout(() => {
            var _a;
            (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.showModal();
        }, 0);
    };
    const handleCloseModal = () => {
        var _a;
        (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.close();
        setTimeout(() => {
            setIsModalOpen(false);
        }, 0);
    };
    return (_jsx("section", { className: styleSheet.root(false), children: _jsxs(VStack, { gap: 0.5, children: [_jsx(HStack, { justify: "end", align: "center", wrap: false, children: _jsx(Button, { size: "small", className: "deice-capacity-control-panel-button", onClick: handleOpenModal, icon: _jsx(GearOutlined, { color: "currentColor" }), children: _jsx("span", { className: "deice-capacity-control-panel-button-text", children: "Settings" }) }) }), isModalOpen && (_jsx(DeIceSettingsModal, { modalRef: modalRef, onClose: handleCloseModal }))] }) }));
};
