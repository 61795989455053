import { useQuery } from "@tanstack/react-query";
import { endOfDay } from "date-fns";
import startOfDay from "date-fns/startOfDay";
import { formatDate } from "@utils/DateUtils";
import { FlightMovementsDirection, FormatPattern } from "@enums";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useGranularityContext } from "@contexts/GranularityContext";
import { useIataContext } from "@contexts/IataContext";
import { useUtcContext } from "@contexts/UtcContext";
import { useFlightMovementDataSource } from "../contexts/FlightMovementsDataSourceContext";
import { useFlightMovementsDirectionFilter } from "../contexts/FlightMovementsDirectionFilterContext";
import { getFlightMovements } from "../services";
const useFlightMovementsChartData = ({ date, iata: iataFromParam }) => {
    const from = date.from;
    const to = date.to;
    const { utc } = useUtcContext();
    const { dataSource } = useFlightMovementDataSource(date.from);
    const { iata: iataFromContext } = useIataContext();
    const iata = iataFromParam || iataFromContext;
    const { picker: { id }, } = useDatePickerContext();
    const { filter } = useFlightMovementsDirectionFilter();
    const { granularity } = useGranularityContext({ from, currentPage: id });
    const { data, error, isLoading } = useQuery({
        queryFn: () => getFlightMovements({
            params: {
                from: formatDate(utc, startOfDay(new Date(from)), FormatPattern.yyyy_MM_dd_HH_mm),
                to: formatDate(utc, endOfDay(new Date(to)), FormatPattern.yyyy_MM_dd_HH_mm),
                international: true,
                domestic: true,
                schengen: true,
                granularity,
                dataSource,
                iata,
            },
        }),
        queryKey: ["getFlightMovements", { utc, iata, from, to, dataSource, granularity }],
    });
    let flightMovements = [];
    if (data && data.flightCounts) {
        data.flightCounts = data.flightCounts.map((flightCount) => {
            return Object.assign({}, flightCount);
        });
        switch (filter.direction) {
            case FlightMovementsDirection.Arrival: {
                flightMovements = data.flightCounts.map((flight) => {
                    const totalFlights = (flight.internationalArrival || 0) +
                        (flight.domesticArrival || 0) +
                        (flight.schengenArrival || 0);
                    return Object.assign(Object.assign({}, flight), { international: flight.internationalArrival, domestic: flight.domesticArrival, schengen: flight.schengenArrival, totalFlights });
                });
                break;
            }
            case FlightMovementsDirection.Departure: {
                flightMovements = data.flightCounts.map((flight) => {
                    const totalFlights = (flight.internationalDeparture || 0) +
                        (flight.domesticDeparture || 0) +
                        (flight.schengenDeparture || 0);
                    return Object.assign(Object.assign({}, flight), { international: flight.internationalDeparture, domestic: flight.domesticDeparture, schengen: flight.schengenDeparture, totalFlights });
                });
                break;
            }
            default: {
                flightMovements = data.flightCounts.map((flight) => {
                    const totalFlights = (flight.internationalTotal || 0) + (flight.domesticTotal || 0) + (flight.schengenTotal || 0);
                    return Object.assign(Object.assign({}, flight), { international: flight.internationalTotal, domestic: flight.domesticTotal, schengen: flight.schengenTotal, totalFlights });
                });
                break;
            }
        }
    }
    return { flightMovements, data, error, date, iata, utc, isLoading };
};
export default useFlightMovementsChartData;
