import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { nb } from "date-fns/locale";
import ReactApexChart from "react-apexcharts";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { SquareArrowInRightOutlined } from "@common-packages/react-icons";
import { removeZuluSuffix } from "@utils/DateUtils";
import NoDataAvailable from "@components/NoDataAvailable/NoDataAvailable";
import Spinner from "@components/Spinner/Spinner";
import { AvinorColor, breakpoints } from "@constants";
import { FormatPattern } from "@enums";
import { getBaggageEnrouteGrouped } from "@features/Baggage/services";
import { defaultApexChartOptions } from "@charts/apexcharts/defaultApexChartOptions";
import { useMediaQuery } from "@hooks";
import { useIataContext } from "@contexts/IataContext";
import { useUtcContext } from "@contexts/UtcContext";
import CustomTooltip from "./CustomTooltip";
export const formatDateXAxisLabelDate = (value, utc) => {
    return format(new Date(utc ? removeZuluSuffix(value) : value), FormatPattern.HH_mm, {
        locale: nb,
    });
};
const BaggageEnrouteGroupedBarChart = ({ hoursTo }) => {
    var _a;
    const { utc } = useUtcContext();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    const { iata } = useIataContext();
    const { data: baggageEnrouteGrouped, isLoading } = useQuery({
        queryFn: () => getBaggageEnrouteGrouped({
            iata,
            hoursTo,
            returnZulu: utc,
        }),
        queryKey: ["getBaggageEnrouteGrouped", { hoursTo, utc, iata }],
    });
    if (isLoading)
        return _jsx(Spinner, {});
    if (!baggageEnrouteGrouped || ((_a = baggageEnrouteGrouped.bagIntervals) === null || _a === void 0 ? void 0 : _a.length) == 0)
        return _jsx(NoDataAvailable, {});
    const xaxisCategories = baggageEnrouteGrouped.bagIntervals.map((flight) => formatDateXAxisLabelDate(flight.from, utc) + " - " + formatDateXAxisLabelDate(flight.to, utc));
    const series = [
        {
            name: t("baggage.widgets.enroutePerFlightEstimateBarChart.pax"),
            group: "pax",
            data: baggageEnrouteGrouped.bagIntervals.map((flight) => flight.incommingPax - flight.transferPax) || [],
        },
        {
            name: t("baggage.widgets.enroutePerFlightEstimateBarChart.transferPax"),
            group: "pax",
            data: baggageEnrouteGrouped.bagIntervals.map((flight) => flight.transferPax) || [],
        },
        {
            name: t("baggage.widgets.enroutePerFlightEstimateBarChart.estimatedBags"),
            group: "bags",
            data: baggageEnrouteGrouped.bagIntervals.map((flight) => flight.estimatedBags - flight.estimatedTransferBags) || [],
        },
        {
            name: t("baggage.widgets.enroutePerFlightEstimateBarChart.estimatedTransferBags"),
            group: "bags",
            data: baggageEnrouteGrouped.bagIntervals.map((flight) => flight.estimatedTransferBags) || [],
        },
    ];
    const sharedStyles = {
        colors: AvinorColor.Grey900,
        fontSize: isMobile ? "0.75rem" : "0.875rem",
        fontWeight: "normal",
    };
    const annotationStyles = {
        marker: {
            size: 4,
            fillColor: "#fff",
            strokeColor: AvinorColor.VioletPrimary,
            radius: 2,
        },
        label: {
            borderColor: AvinorColor.VioletPrimary,
            style: {
                borderRadius: 4,
                color: AvinorColor.VioletPrimary,
                fontWeight: 600,
            },
            text: "",
        },
    };
    const getAnnotationPoints = () => {
        const points = [];
        xaxisCategories.forEach((cat, index) => {
            const pax = series[0].data[index];
            const transferPax = series[1].data[index];
            const totalPax = pax + transferPax;
            const bags = series[2].data[index];
            const transferBags = series[3].data[index];
            const totalBags = bags + transferBags;
            const paxAnnotation = Object.assign(Object.assign({}, annotationStyles), { label: Object.assign(Object.assign({}, annotationStyles.label), { style: Object.assign({}, annotationStyles.label.style), text: "" + totalPax }), x: cat, seriesIndex: 1, y: totalPax });
            const bagsAnnotation = Object.assign(Object.assign({}, annotationStyles), { label: Object.assign(Object.assign({}, annotationStyles.label), { style: Object.assign({}, annotationStyles.label.style), text: "" + totalBags }), x: cat, seriesIndex: 2, y: totalBags });
            if (totalPax > 0)
                points.push(paxAnnotation);
            if (totalBags > 0)
                points.push(bagsAnnotation);
        });
        return points;
    };
    const options = {
        colors: [
            AvinorColor.MintGreen400,
            AvinorColor.MintGreen300,
            AvinorColor.VioletPrimary,
            AvinorColor.VioletPrimaryLight,
        ],
        chart: {
            toolbar: {
                tools: {
                    download: ReactDOMServer.renderToStaticMarkup(_jsx(SquareArrowInRightOutlined, { style: { transform: "rotate(90deg) scale(0.75)" } })),
                },
                offsetY: 0,
            },
            fontFamily: "Roboto, sans-serif",
            type: "bar",
            stacked: true,
            zoom: {
                type: "x",
                allowMouseWheelZoom: false,
            },
            offsetX: 5,
        },
        grid: {
            show: true,
            strokeDashArray: 4,
            borderColor: AvinorColor.Grey200,
            xaxis: {
                lines: {
                    show: true,
                },
            },
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [0, 1, 2, 3, 4],
            style: {
                colors: [AvinorColor.White, AvinorColor.White, AvinorColor.White, AvinorColor.White],
            },
        },
        xaxis: {
            categories: xaxisCategories,
            tickPlacement: "between",
            title: {
                text: t("baggage.widgets.enroutePerFlightEstimateBarChart.time"),
                style: sharedStyles,
            },
            labels: {
                style: sharedStyles,
                datetimeUTC: utc,
                format: FormatPattern.HH_mm,
            },
        },
        yaxis: {
            floating: false,
            min: 0,
            title: {
                text: t("baggage.widgets.enroutePerFlightEstimateBarChart.amount"),
                style: sharedStyles,
            },
            labels: {
                formatter: function (value) {
                    return Math.round(value).toString();
                },
                style: sharedStyles,
                offsetX: 10,
            },
        },
        legend: Object.assign(Object.assign({}, defaultApexChartOptions.legend), { onItemClick: {
                toggleDataSeries: false,
            } }),
        annotations: {
            points: getAnnotationPoints(),
        },
        tooltip: {
            fixed: {
                enabled: false,
            },
            custom: function ({ dataPointIndex, series, seriesIndex }) {
                const pax = series[0][dataPointIndex];
                const transferPax = series[1][dataPointIndex];
                const totalPax = pax + transferPax;
                const bags = series[2][dataPointIndex];
                const transferBags = series[3][dataPointIndex];
                const totalBags = bags + transferBags;
                const baggageData = Object.assign(Object.assign({}, baggageEnrouteGrouped.bagIntervals[dataPointIndex]), { paxInformation: [
                        {
                            value: transferPax,
                            color: AvinorColor.MintGreen300,
                            name: t("baggage.widgets.enroutePerFlightEstimateBarChart.transferPax"),
                            active: seriesIndex === 1,
                        },
                        {
                            value: pax,
                            color: AvinorColor.MintGreen400,
                            name: t("baggage.widgets.enroutePerFlightEstimateBarChart.pax"),
                            active: seriesIndex === 0,
                        },
                    ], baggageInformation: [
                        {
                            value: transferBags,
                            color: AvinorColor.VioletPrimaryLight,
                            name: t("baggage.widgets.enroutePerFlightEstimateBarChart.estimatedTransferBags"),
                            active: seriesIndex === 3,
                        },
                        {
                            value: bags,
                            color: AvinorColor.VioletPrimary,
                            name: t("baggage.widgets.enroutePerFlightEstimateBarChart.estimatedBags"),
                            active: seriesIndex === 2,
                        },
                    ], totalBags: totalBags, totalPax: totalPax });
                const tooltipElement = _jsx(CustomTooltip, { baggageData: baggageData });
                return ReactDOMServer.renderToStaticMarkup(tooltipElement);
            },
        },
    };
    return (_jsx(ReactApexChart, { options: options, series: series, type: "bar", height: isMobile ? 700 : 500 }));
};
export default BaggageEnrouteGroupedBarChart;
