import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./DeIceApronHeaterStatus.scss";
import { Switch } from "@digdir/designsystemet-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { isBefore, startOfDay } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@components/Spinner/Spinner";
import { useIataContext } from "@contexts/IataContext";
import { getApronHeaterStatus, saveApronHeaterStatus } from "../services";
export const DeIceApronHeaterStatus = ({ date }) => {
    var _a;
    const { t } = useTranslation();
    const { iata } = useIataContext();
    const queryClient = useQueryClient();
    const [apronHeaterStatus, setApronHeaterStatus] = useState();
    const isDateBeforeToday = isBefore(new Date(date), startOfDay(new Date()));
    const { data: apronHeaterStatusData, isLoading } = useQuery({
        queryFn: () => getApronHeaterStatus({
            date,
            iata,
        }),
        queryKey: ["apronHeaterStatusData", { date, iata }],
    });
    const saveApronHeaterStatusMutation = useMutation({
        mutationFn: ({ iata, apronHeaterStatus }) => saveApronHeaterStatus({
            body: {
                iata,
                apronHeaterStatus,
            },
        }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["apronHeaterStatusData"] });
        },
        onError: (error) => {
            console.error(`saveApronHeaterStatus() ERROR \n${error}`);
        },
    });
    useEffect(() => {
        if (apronHeaterStatusData && apronHeaterStatusData.length > 0) {
            // Find the latest entry by comparing updatedAt
            const latestEntry = apronHeaterStatusData.reduce((latest, current) => new Date(current.updatedAt) > new Date(latest.updatedAt) ? current : latest);
            // Set the apronHeaterStatus based on the latest entry
            setApronHeaterStatus(latestEntry);
        }
    }, [apronHeaterStatusData]);
    const toggleStatus = (status) => {
        saveApronHeaterStatusMutation.mutate({ iata, apronHeaterStatus: status });
    };
    if (isLoading || saveApronHeaterStatusMutation.isPending) {
        return _jsx(Spinner, {});
    }
    return (_jsxs("div", { className: "deice-capacity-apron-heater", children: [_jsx(Switch, { "data-size": "md", description: "", position: "left", disabled: isDateBeforeToday, checked: (_a = apronHeaterStatus === null || apronHeaterStatus === void 0 ? void 0 : apronHeaterStatus.apronHeaterStatus) !== null && _a !== void 0 ? _a : false, onChange: () => toggleStatus(!(apronHeaterStatus === null || apronHeaterStatus === void 0 ? void 0 : apronHeaterStatus.apronHeaterStatus)), children: (apronHeaterStatus === null || apronHeaterStatus === void 0 ? void 0 : apronHeaterStatus.apronHeaterStatus) ? t("deice.apronHeater.on") : t("deice.apronHeater.off") }), (apronHeaterStatus === null || apronHeaterStatus === void 0 ? void 0 : apronHeaterStatus.apronHeaterStatus) && (_jsx("img", { src: "/images/heater.gif" // Replace with actual path to your GIF file
                , className: isDateBeforeToday ? "deice-capacity-apron-heater-on-gif-disabled" : "" }))] }));
};
