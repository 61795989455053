import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import { formatDate } from "@utils/DateUtils";
import { FormatPattern } from "@enums";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useIataContext } from "@contexts/IataContext";
import { postDeIceCapacityAndPadConfiguration, postDeIceWeatherData } from "../services";
export const useSubmitDeIceCapacityAndPadConfig = () => {
    const queryClient = useQueryClient();
    const { iata: iataCode } = useIataContext();
    const { picker: { from }, } = useDatePickerContext();
    const deIceDate = formatDate(true, from, FormatPattern.yyyy_MM_dd);
    const mutation = useMutation({
        mutationFn: async ({ capacity, pads, baseWeather, updatedAt, weatherDeviations, }) => {
            // Perform both API calls in parallel
            const [capacityResult, weatherResult] = await Promise.all([
                postDeIceCapacityAndPadConfiguration({
                    body: {
                        iata: iataCode,
                        date: deIceDate,
                        handlerCapacity: capacity,
                        pads,
                    },
                }),
                postDeIceWeatherData({
                    body: {
                        iata: iataCode,
                        date: deIceDate,
                        updatedAt,
                        baseWeather,
                        weatherDeviations: weatherDeviations.map((deviation) => {
                            return {
                                weather: deviation.weather,
                                from: format(parseISO(deviation.from), "yyyy-MM-dd'T'HH:mm"),
                                to: format(parseISO(deviation.to), "yyyy-MM-dd'T'HH:mm"),
                            };
                        }),
                    },
                }),
            ]);
            return { capacityResult, weatherResult };
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["getPadConfiguration"] });
            queryClient.invalidateQueries({ queryKey: ["getDeIcePlan"] });
            queryClient.invalidateQueries({ queryKey: ["getDeIceCapacity"] });
            queryClient.invalidateQueries({ queryKey: ["getDeIceWeatherData"] });
        },
        onError: (error) => {
            console.error(`postDeIceConfig() ERROR \n${error}`);
        },
    });
    const submitDeIceCapacityAndPadConfig = (capacity, pads, baseWeather, updatedAt, weatherDeviations) => {
        mutation.mutate({ capacity, pads, baseWeather, updatedAt, weatherDeviations });
    };
    const resetMutation = () => {
        mutation.reset();
    };
    return {
        submitDeIceCapacityAndPadConfig,
        operationStatus: mutation.status, // 'idle' | 'loading' | 'error' | 'success'
        error: mutation.error,
        resetMutation: resetMutation,
    };
};
