import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Runway } from "@common-packages/react-icons";
import Widget from "@components/Widget/Widget";
import { OnTimePerformanceServiceQualityRunway } from "@features/OnTimePerformance/components";
export const OnTimePerformanceServiceQualityRunwayTableWidget = ({ smallFormat }) => {
    return (_jsxs(Widget.Container, { children: [!smallFormat && (_jsx(Widget.Header, { title: "Runway", subtitleVisibility: "none", icon: {
                    component: (_jsx(Runway, { width: "2em", height: "2em" })),
                } })), _jsx(Widget.Body, { children: _jsx(OnTimePerformanceServiceQualityRunway, { smallFormat: smallFormat }) })] }));
};
OnTimePerformanceServiceQualityRunwayTableWidget.displayName = "OnTimePerformanceServiceQualityRunwayTableWidget";
