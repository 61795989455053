import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { PeopleGroup3Outlined } from "@common-packages/react-icons";
import Widget from "@components/Widget/Widget";
import { useIataContext } from "@contexts/IataContext";
import { PaxEnrouteTable } from "../components/PaxEnrouteTable";
export const PaxEnrouteTableWidget = ({ title, subTitle, footerText, }) => {
    const { iata } = useIataContext();
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t(title, { iata }), subtitle: t(subTitle), icon: {
                    component: (_jsx(PeopleGroup3Outlined, { width: "1.5rem", height: "1.5rem" })),
                } }), _jsx(Widget.Body, { children: _jsx(PaxEnrouteTable, {}) }), _jsx(Widget.Footer, { children: footerText })] }));
};
PaxEnrouteTableWidget.displayName = "PaxEnrouteTableWidget";
