import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { PenOutlined, InfoICircleOutlined } from "@common-packages/react-icons";
import { Tooltip } from "react-tooltip";
import { usePlanDayPickerContext } from "@features/Plan/contexts/PlanDayPickerContext";
import useRoles from "@auth/hooks/useRoles";
export const PlanOverviewEditButton = ({ processId, processActor, setSelectedProcessTypeId, }) => {
    const { hasPlanWriteRole } = useRoles();
    const { dateIsPast } = usePlanDayPickerContext();
    return (_jsx(_Fragment, { children: hasPlanWriteRole ? (dateIsPast ? (_jsx(InfoICircleOutlined, { title: "Info", fontSize: "16px", color: "var(--ds-color-accent-12)", className: "plan-overview-accordion-process-status-edit-icon", onClick: () => {
                setSelectedProcessTypeId(processId);
            } })) : (_jsx(PenOutlined, { title: "Edit", fontSize: "16px", color: "var(--ds-color-accent-12)", className: "plan-overview-accordion-process-status-edit-icon", onClick: () => {
                setSelectedProcessTypeId(processId);
            } }))) : (_jsxs(_Fragment, { children: [_jsx("div", { "data-tooltip-id": "edit-tooltip-" + processActor, "data-tooltip-html": `Du er ikke autorisert`, "data-tooltip-place": "top", "data-tooltip-position-strategy": "fixed", children: dateIsPast ? (_jsx(InfoICircleOutlined, { title: "Info", fontSize: "16px", color: "var(--ds-color-accent-12)", className: "plan-overview-accordion-process-status-info-icon-disabled" })) : (_jsx(PenOutlined, { title: "Edit", fontSize: "16px", color: "var(--ds-color-accent-12)", className: "plan-overview-accordion-process-status-edit-icon plan-overview-accordion-process-status-edit-icon-disabled" })) }), _jsx(Tooltip, { id: "edit-tooltip-" + processActor })] })) }));
};
