import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./AirportTrainsStatusesTable.scss";
import { Delay } from "@enums/delay";
import { Cell } from "@table-library/react-table-library";
import { useSort, SortToggleType } from "@table-library/react-table-library/sort";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowDown, ArrowsUpDown, ArrowUp } from "@common-packages/react-icons";
import { mapTrainCompanyName } from "@utils/Utils";
import Expandable from "@components/Expandable/Expandable";
import NoDataAvailable from "@components/NoDataAvailable/NoDataAvailable";
import Table from "@components/Table/Table";
import { addIdToObj } from "@components/Table/utils";
import TrainOperatorIcon from "@components/TrainOperatorIcon/TrainOperatorIcon";
import { getAirportTrainsStatuses } from "@features/AirportTransportation/services";
import { useIataContext } from "@contexts/IataContext";
import AirportTrainsDetailsTable from "../AirportTrainsDetailsTable/AirportTrainsDetailsTable";
import AirportTransportationAlertIndicator from "../AirportTransportationAlertIndicator/AirportTransportationAlertIndicator";
import AlertIndicator from "../AlertIndicator/AlertIndicator";
const styleSheet = {
    trainsStatuses: "trains-statuses",
    operatorCell: "trains-statuses__operator-cell",
    footerCell: "trains-statuses__footer-cell",
};
const tableColumnHeaders = {
    top: {
        operator: "operator",
        today: "today",
    },
    bottom: {
        emptyCell: "",
        todayArrivals: "common.arrivals",
        todayDepartures: "common.departures",
    },
};
const headers = {
    top: Object.keys(tableColumnHeaders.top),
    bottom: Object.keys(tableColumnHeaders.bottom),
};
const getTableColumnHeaders = (t) => {
    return [
        {
            headers: [
                {
                    content: t(tableColumnHeaders.top.operator),
                    key: headers.top[0],
                    cellProps: {
                        gridColumnStart: 1,
                        gridColumnEnd: 3,
                    },
                },
                {
                    content: t(tableColumnHeaders.top.today),
                    key: headers.top[1],
                    cellProps: {
                        gridColumnStart: 3,
                        gridColumnEnd: 5,
                    },
                },
            ],
        },
        {
            headers: [
                {
                    content: tableColumnHeaders.bottom.emptyCell,
                    key: headers.bottom[0],
                    cellProps: {
                        gridColumnStart: 1,
                        gridColumnEnd: 3,
                    },
                },
                {
                    content: t(tableColumnHeaders.bottom.todayArrivals),
                    key: headers.bottom[1],
                    sortKey: headers.bottom[1],
                    cellProps: {
                        gridColumnStart: 3,
                        gridColumnEnd: 4,
                    },
                },
                {
                    content: t(tableColumnHeaders.bottom.todayDepartures),
                    key: headers.bottom[2],
                    sortKey: headers.bottom[2],
                    cellProps: {
                        gridColumnStart: 4,
                        gridColumnEnd: 5,
                    },
                },
            ],
        },
    ];
};
const sortByDelaysAndCancellations = (key) => (array) => {
    return array.sort((a, b) => a[key].delays + a[key].cancellations - (b[key].delays + b[key].cancellations));
};
const TrainsStatusesCell = ({ cellIndex, value, hide }) => (_jsxs(Cell, { hide: hide, children: [_jsx(AirportTransportationAlertIndicator, { value: value.cancellations, variant: "alarm" }), _jsx(AirportTransportationAlertIndicator, { value: value.delays, variant: "warning" })] }, cellIndex));
const ExpandableTrainDetailsTable = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { t } = useTranslation();
    return (_jsx(Expandable, { onToggle: () => setIsExpanded((prevIsExpanded) => !prevIsExpanded), isExpanded: isExpanded, buttonText: {
            expanded: t("common.hideDetails"),
            collapsed: t("common.showDetails"),
        }, children: _jsx(AirportTrainsDetailsTable, {}) }));
};
const AirportTrainsStatusesTable = () => {
    const { iata } = useIataContext();
    const { data: airportTrainsStatusesData } = useQuery({
        queryFn: () => getAirportTrainsStatuses({
            iata: iata,
        }),
        queryKey: ["getAirportTrainsStatuses", { iata }],
        refetchInterval: Delay.TwoMinutes,
    });
    const { t } = useTranslation();
    const { nodes } = addIdToObj(airportTrainsStatusesData || []);
    const airportTrainsStatusesSortOptions = useSort({ nodes: nodes }, {}, {
        sortToggleType: SortToggleType.AlternateWithReset,
        sortIcon: {
            size: "24px",
            iconDefault: _jsx(ArrowsUpDown, {}),
            iconUp: _jsx(ArrowUp, {}),
            iconDown: _jsx(ArrowDown, {}),
        },
        sortFns: {
            todayArrivals: sortByDelaysAndCancellations("todayArrivals"),
            todayDepartures: sortByDelaysAndCancellations("todayDepartures"),
        },
    });
    if (!airportTrainsStatusesData || (airportTrainsStatusesData === null || airportTrainsStatusesData === void 0 ? void 0 : airportTrainsStatusesData.length) === 0)
        return _jsx(NoDataAvailable, {});
    const customCellRenderers = {
        operator: ({ cellIndex, value, gridColumnStart, gridColumnEnd }) => {
            return (_jsxs(Cell, { className: styleSheet.operatorCell, gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, children: [_jsx(TrainOperatorIcon, { operator: value }), _jsx("p", { children: mapTrainCompanyName(value) })] }, cellIndex));
        },
        todayArrivals: (cellProps) => {
            return _jsx(TrainsStatusesCell, Object.assign({}, cellProps));
        },
        todayDepartures: (cellProps) => {
            return _jsx(TrainsStatusesCell, Object.assign({}, cellProps));
        },
    };
    const keysToExclude = ["trainDetails", "tomorrowArrivals", "tomorrowDepartures"];
    return (_jsxs(_Fragment, { children: [_jsx(Table, { className: styleSheet.trainsStatuses, data: { nodes }, headerGroups: getTableColumnHeaders(t), sort: airportTrainsStatusesSortOptions, cellRenderers: customCellRenderers, excludeKeys: keysToExclude, customTheme: {
                    Cell: `
                        text-align: start;

                        &:not(:first-of-type) {
                            
                            & > div {
                                display: flex; 
                                gap: 1em; 
                                font-size: 0.75rem;
                            }
                        }

                        & > div {
                            margin-left: 0.5rem;
                        }
                    `,
                    FooterCell: `
                        & > div {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 1rem;
                        }
                    `,
                }, footerText: _jsxs(_Fragment, { children: [_jsx(AlertIndicator, { variant: "alarm" }), t("cancelled"), _jsx(AlertIndicator, { variant: "warning" }), t("delayed")] }) }), _jsx(ExpandableTrainDetailsTable, {})] }));
};
AirportTrainsStatusesTable.displayName = "AirportTrainsStatusesTable";
export default AirportTrainsStatusesTable;
