import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { getProgressStatusText, roundIfNeeded } from "@utils/Utils";
import { Grid, VStack } from "@components/Layout";
import Progress from "@components/Progress/Progress";
import { ProgressCard } from "@components/ProgressCard/ProgressCard";
import Spinner from "@components/Spinner/Spinner";
import { breakpoints } from "@constants";
import { useMediaQuery } from "@hooks";
import useRegularityAndPunctualityPeriodData from "../hooks/useRegularityAndPunctualityPeriodData";
const ProgressCancelled = () => {
    const { data, isLoading } = useRegularityAndPunctualityPeriodData({ paxOnly: false, direction: "D" });
    const { t } = useTranslation();
    const { totalCancelledFlights = 0, totalFlightsOperated = 0 } = data || {};
    const totalFlights = totalCancelledFlights + totalFlightsOperated;
    const percent = totalFlights ? (1 - totalCancelledFlights / totalFlights) * 100 : 0;
    const completed = totalFlights;
    if (isLoading)
        return _jsx(Spinner, {});
    return (_jsx(ProgressCard, { variant: "plain", progressVisibility: "hidden", title: t("common.cancellations"), description: t("common.progressStatusCompletedSoFar", {
            percent: roundIfNeeded(percent),
            completed,
        }), value: (data === null || data === void 0 ? void 0 : data.totalCancelledFlights) || 0 }));
};
const ProgressArrival = () => {
    const { data, isLoading } = useRegularityAndPunctualityPeriodData({ paxOnly: false, direction: "A" });
    const { t } = useTranslation();
    if (isLoading)
        return _jsx(Spinner, {});
    return (_jsx(ProgressCard, { title: t("common.arrival"), description: getProgressStatusText(data === null || data === void 0 ? void 0 : data.totalRegularity, data === null || data === void 0 ? void 0 : data.totalFlightsOperated, data === null || data === void 0 ? void 0 : data.totalFlights), value: (data === null || data === void 0 ? void 0 : data.totalRegularity) || 0 }));
};
export const RegularityTotalOverview = ({ smallFormat }) => {
    const { data: punctualityTotalData, isLoading } = useRegularityAndPunctualityPeriodData({
        paxOnly: false,
        direction: "D",
    });
    const { t } = useTranslation();
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    const progress = punctualityTotalData === null || punctualityTotalData === void 0 ? void 0 : punctualityTotalData.totalRegularity;
    const operatedFlights = punctualityTotalData === null || punctualityTotalData === void 0 ? void 0 : punctualityTotalData.totalFlightsOperated;
    const plannedFlights = punctualityTotalData === null || punctualityTotalData === void 0 ? void 0 : punctualityTotalData.totalFlights;
    return (_jsxs(VStack, { gap: 0.5, style: { height: "100%" }, children: [isLoading ? (_jsx(Spinner, {})) : (_jsx(Progress, { completedPercentage: progress, text: t("common.progressStatusCompletedAndPlanned", {
                    percent: roundIfNeeded(progress || 0),
                    completed: operatedFlights,
                    planned: plannedFlights,
                }), withPadding: true })), !smallFormat && (_jsxs(Grid, { columns: 2, gap: isMobile ? 0.5 : 1, children: [_jsx(ProgressCancelled, {}), _jsx(ProgressArrival, {})] }))] }));
};
