import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { nb } from "date-fns/locale";
import ReactApexChart from "react-apexcharts";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { Home, ZoomArea, Move, CirclePlus, CircleMinus } from "@common-packages/react-icons";
import { formatDate } from "@utils/DateUtils";
import { extractUniqueKeys } from "@utils/Utils";
import NoDataAvailable from "@components/NoDataAvailable/NoDataAvailable";
import Spinner from "@components/Spinner/Spinner";
import { AvinorColor, breakpoints } from "@constants";
import { FormatPattern } from "@enums";
import { mapAirlineIataToColor, mapAirlineIataToName } from "@mappings";
import { getPunctualityDay } from "@features/OnTimePerformance/services";
import { defaultApexChartOptions } from "@charts/apexcharts/defaultApexChartOptions";
import Tooltip from "@charts/shared/components/Tooltip/Tooltip";
import { formatXAxisTick } from "@charts/utils";
import { useMediaQuery } from "@hooks";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useGranularityContext } from "@contexts/GranularityContext";
import { useIataContext } from "@contexts/IataContext";
import { useUtcContext } from "@contexts/UtcContext";
const createChartLines = (punctualityDayData) => {
    const excludeKeys = new Set(["dateTime"]);
    const uniqueAirlineCodes = extractUniqueKeys(punctualityDayData, excludeKeys);
    return uniqueAirlineCodes.map((iata) => ({
        dataKey: iata,
        stroke: mapAirlineIataToColor(iata),
    }));
};
const LineChart = () => {
    const { utc } = useUtcContext();
    const { picker: { from, id }, } = useDatePickerContext();
    const { granularity } = useGranularityContext({ from, currentPage: id });
    const { t } = useTranslation();
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    const { iata } = useIataContext();
    const { data: punctualityDayData, isLoading } = useQuery({
        queryFn: () => getPunctualityDay({
            iata,
            date: formatDate(utc, from, FormatPattern.yyyy_MM_dd),
        }),
        queryKey: ["getPunctualityDay", { from, iata, utc }],
    });
    if (isLoading)
        return _jsx(Spinner, {});
    if (!punctualityDayData || !punctualityDayData.punctualities || punctualityDayData.punctualities.length === 0)
        return _jsx(NoDataAvailable, {});
    const series = createChartLines(punctualityDayData.punctualities).map(({ dataKey, stroke }) => ({
        name: mapAirlineIataToName(dataKey, () => {
            return t(dataKey);
        }),
        data: punctualityDayData.punctualities.map((punctuality) => { var _a; return (_a = punctuality[dataKey]) !== null && _a !== void 0 ? _a : 0; }),
        stroke,
    }));
    const xaxisCategories = punctualityDayData.punctualities.map((punctuality) => punctuality.dateTime);
    const sharedStyles = {
        colors: AvinorColor.Grey900,
        fontSize: isMobile ? "0.75rem" : "0.875rem",
        fontWeight: "normal",
    };
    const options = {
        colors: series.map((s) => s.stroke),
        chart: {
            toolbar: {
                tools: {
                    download: false,
                    zoom: ReactDOMServer.renderToStaticMarkup(_jsx(ZoomArea, {})),
                    zoomin: ReactDOMServer.renderToStaticMarkup(_jsx(CirclePlus, {})),
                    zoomout: ReactDOMServer.renderToStaticMarkup(_jsx(CircleMinus, {})),
                    pan: ReactDOMServer.renderToStaticMarkup(_jsx(Move, {})),
                    reset: ReactDOMServer.renderToStaticMarkup(_jsx(Home, {})),
                },
            },
            fontFamily: "Roboto, sans-serif",
            type: "line",
            zoom: {
                type: "x",
                allowMouseWheelZoom: false,
            },
        },
        grid: {
            show: true,
            strokeDashArray: 4,
            xaxis: {
                lines: {
                    show: true,
                },
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (value, opts) {
                const nth = 15;
                if (!value)
                    return "";
                if (Number(value) === 100)
                    return "";
                if (opts.dataPointIndex % nth === 0) {
                    return Math.round(value);
                }
                return "";
            },
        },
        stroke: {
            width: 2,
            curve: "smooth",
        },
        xaxis: {
            categories: xaxisCategories,
            type: "datetime",
            title: {
                text: t("time"),
                style: sharedStyles,
                offsetY: 5,
            },
            labels: {
                datetimeUTC: utc,
                formatter: function (value) {
                    if (!value)
                        return "";
                    return format(new Date(value), FormatPattern.HH_mm, { locale: nb });
                },
            },
        },
        yaxis: {
            max: 100,
            title: {
                text: `${t("percent")} (%)`,
                style: sharedStyles,
            },
            labels: {
                formatter: function (percent) {
                    return `${Math.round(percent)}`;
                },
                offsetX: 10,
            },
        },
        responsive: [
            {
                breakpoint: 768,
                options: {
                    xaxis: {
                        title: {
                            text: undefined,
                        },
                    },
                    yaxis: {
                        title: {
                            text: undefined,
                        },
                        labels: {
                            formatter: function (percent) {
                                return `${Math.round(percent)}`;
                            },
                            offsetX: -10,
                        },
                    },
                },
            },
        ],
        tooltip: {
            fixed: {
                enabled: true,
                position: "bottomRight",
            },
            custom: function ({ dataPointIndex }) {
                const { dateTime } = punctualityDayData.punctualities[dataPointIndex];
                const tooltipElement = (_jsxs(Tooltip, { children: [_jsx(Tooltip.Title, { children: formatXAxisTick(dateTime, granularity, t("timePrefix")) }), _jsx(Tooltip.List, { children: Object.entries(punctualityDayData.punctualities[dataPointIndex])
                                .filter(([key]) => {
                                return !["dateTime"].includes(key);
                            })
                                .map(([airlineIata, percent]) => {
                                return (_jsx(Tooltip.ListItem, { style: {
                                        type: "bullet",
                                        bgColor: mapAirlineIataToColor(airlineIata),
                                    }, content: {
                                        value: mapAirlineIataToName(airlineIata, () => {
                                            return t(airlineIata);
                                        }),
                                    }, status: {
                                        value: `${Math.round(Number(percent))}%`,
                                    } }, airlineIata));
                            }) })] }));
                return ReactDOMServer.renderToStaticMarkup(tooltipElement);
            },
        },
        legend: defaultApexChartOptions.legend,
    };
    return (_jsx(ReactApexChart, { options: options, series: series, type: "line", height: 400 }));
};
export default LineChart;
