import { useQuery } from "@tanstack/react-query";
import { formatDate } from "@utils/DateUtils";
import { FormatPattern } from "@enums";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useIataContext } from "@contexts/IataContext";
import { useUtcContext } from "@contexts/UtcContext";
import { getDeIceWeatherData } from "../services";
const useDeIceWeatherData = () => {
    const { iata } = useIataContext();
    const { utc } = useUtcContext();
    const { picker: { from }, } = useDatePickerContext();
    const { data, error, isLoading } = useQuery({
        queryFn: () => getDeIceWeatherData({
            iata,
            date: formatDate(utc, from, FormatPattern.yyyy_MM_dd),
        }),
        queryKey: ["getDeIceWeatherData", { from, utc, iata }],
        refetchInterval: 0,
        staleTime: 0,
        refetchOnWindowFocus: false,
        gcTime: 0,
    });
    return { data, error, from, utc, iata, isLoading };
};
export default useDeIceWeatherData;
