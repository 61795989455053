import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./DeIceWeatherCreateDeviationModal.scss";
import { Button, Modal, NativeSelect, Paragraph } from "@digdir/designsystemet-react";
import { TrashIcon } from "@navikt/aksel-icons";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EWeatherCondition } from "@features/DeIce/enums";
export const DeIceWeatherCreateDeviationModal = ({ modalRef, selectedDeviation, cancelDeviation, createOrEditDeviation, deleteDeviation, }) => {
    const { t } = useTranslation();
    const [deviation, setDeviation] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [isNewDeviation, setIsNewDeviation] = useState(false);
    useEffect(() => {
        if (selectedDeviation) {
            setDeviation(selectedDeviation);
            setIsNewDeviation(!selectedDeviation.weather);
        }
        setErrorMessage("");
    }, [selectedDeviation]);
    const onConfirm = () => {
        if (deviation.weather) {
            createOrEditDeviation(deviation);
            closeModal();
        }
        else {
            setErrorMessage("Required");
        }
    };
    const onDelete = () => {
        deleteDeviation(deviation);
        closeModal();
    };
    const closeModal = () => {
        var _a;
        (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.close();
    };
    const onModalClose = () => {
        cancelDeviation();
    };
    const handleWeatherChange = (e) => {
        setDeviation((prev) => (Object.assign(Object.assign({}, prev), { weather: e.target.value })));
        setErrorMessage("");
    };
    return (_jsx("div", { className: "deice-weather-deviation-modal", children: _jsx(Modal.Root, { children: _jsxs(Modal.Dialog, { ref: modalRef, onClose: () => onModalClose(), children: [_jsxs(Modal.Header, { children: [t("deice.weather.deviationModal.title"), _jsx(Paragraph, { size: "sm", children: deviation.from && deviation.to && (_jsx(_Fragment, { children: `${format(new Date(deviation.from), "HH:mm")} - ${format(new Date(deviation.to), "HH:mm")}` })) })] }), _jsx(Modal.Content, { children: _jsx("div", { children: _jsxs(NativeSelect, { label: t("deice.weather.conditions.chooseType") + "*", size: "sm", value: deviation.weather, onChange: handleWeatherChange, required: true, error: errorMessage, children: [_jsxs("option", { value: "", disabled: true, hidden: true, children: [t("deice.weather.conditions.choose"), "..."] }), Object.entries(EWeatherCondition).map(([key, value]) => (_jsx("option", { value: key, children: key + " - " + value }, key)))] }) }) }), _jsx(Modal.Footer, { children: _jsxs("div", { style: { display: "flex", justifyContent: "space-between", width: "100%", gap: "10px" }, children: [_jsx("div", { children: !isNewDeviation && !deviation.allDay && (_jsxs(Button, { color: "danger", size: "sm", variant: "secondary", onClick: () => onDelete(), children: [_jsx(TrashIcon, { "aria-hidden": true, fontSize: "1.5rem" }), t("delete")] })) }), _jsx(Button, { color: "accent", size: "sm", variant: "primary", onClick: () => onConfirm(), children: t("confirm") })] }) })] }) }) }));
};
