import { jsx as _jsx } from "react/jsx-runtime";
import { isAfter, isBefore, isToday, startOfDay } from "date-fns";
import { useTranslation } from "react-i18next";
import { FlightMovementsDirectionFilterProvider } from "@features/FlightMovements/contexts/FlightMovementsDirectionFilterContext";
import { PlanDayPickerProvider } from "@features/Plan/contexts/PlanDayPickerContext";
import { PlanOverviewWidgetsToday, PlanOverviewWidgetsTomorrow, PlanOverviewWidgetsYesterday } from "@widgets/registry";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useIataContext } from "@contexts/IataContext";
import PageLayout from "./PageLayout/PageLayout";
const PlanOverviewPage = () => {
    const { t } = useTranslation();
    const { iata } = useIataContext();
    const { picker: { from }, } = useDatePickerContext();
    const normalizedFrom = startOfDay(new Date(from));
    const today = startOfDay(new Date());
    const widgetsToShow = isBefore(normalizedFrom, today)
        ? PlanOverviewWidgetsYesterday
        : isToday(normalizedFrom)
            ? PlanOverviewWidgetsToday
            : isAfter(normalizedFrom, today)
                ? PlanOverviewWidgetsTomorrow
                : [];
    return (_jsx(FlightMovementsDirectionFilterProvider, { children: _jsx(PlanDayPickerProvider, { children: _jsx(PageLayout, { title: t("plan.overview.title", { iata }), description: t("plan.overview.description"), widgets: widgetsToShow }) }) }));
};
export default PlanOverviewPage;
