import { format } from "date-fns";
export const getDeviationEvents = (deIceWeather, from, isDisabled = false) => {
    if (deIceWeather && deIceWeather.weatherDeviations) {
        const events = deIceWeather.weatherDeviations.map((deviation) => {
            return createEventObject(deviation, isDisabled);
        });
        events.push(getAllDayDeviation(deIceWeather, from, isDisabled));
        return events;
    }
    return [];
};
export const getAllDayDeviation = (deIceWeather, from, isDisabled) => {
    const allDayEventStart = format(new Date(from), "yyyy-MM-dd 00:00");
    const allDayEventEnd = format(new Date(from), "yyyy-MM-dd 23:59");
    const allWeatherDeviation = {
        from: allDayEventStart,
        to: allDayEventEnd,
        weather: deIceWeather.baseWeather || "DEFAULT",
    };
    const allDayEvent = createEventObject(allWeatherDeviation, isDisabled);
    allDayEvent.allDay = true;
    return allDayEvent;
};
//Get the class name for the event based on the deviation and if it is editable
export const getEventClassname = (weatherKey, isDisabled) => {
    let className = "deice-weather-calendar-event-normal";
    if (weatherKey) {
        className = "deice-weather-calendar-event-" + weatherKey;
    }
    if (isDisabled) {
        className += " deice-weather-calendar-event-disabled";
    }
    return className;
};
export const createEventObject = (deviation, isDisabled) => {
    const event = {};
    event.id = createEventKey(deviation);
    event.title = deviation.weather;
    event.start = deviation.from;
    event.end = deviation.to;
    event.extendedProps = deviation;
    event.className = getEventClassname(deviation.weather, isDisabled);
    return event;
};
export const createEventKey = (deviation) => {
    return deviation.from + deviation.to;
};
