import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./DeIceWeatherCalendarEvent.scss";
import { EWeatherCondition } from "@features/DeIce/enums";
export const DeIceWeatherCalendarEvent = ({ eventInfo }) => {
    const deviation = eventInfo.event.extendedProps;
    if (!deviation || !deviation.from)
        return _jsx(_Fragment, { children: eventInfo.timeText });
    const weatherCondition = deviation.weather && deviation.weather in EWeatherCondition
        ? EWeatherCondition[deviation.weather]
        : null;
    return (_jsx("div", { className: "deice-weather-calendar-event", children: _jsxs("div", { className: "deice-weather-calendar-event-timelabel", children: [eventInfo.timeText && _jsxs(_Fragment, { children: [" ", eventInfo.timeText, " | "] }), deviation.weather && (_jsxs(_Fragment, { children: [_jsx("b", { children: deviation.weather }), " |", " ", _jsx("div", { className: "deice-weather-calendar-event-timelabel-disruption", children: _jsxs("span", { children: [" ", weatherCondition] }) })] }))] }) }));
};
