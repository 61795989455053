import { httpFlightMovementsService, httpPaxService } from "@services/HttpService";
import { format } from "date-fns";
import { resolveUrl } from "@utils/UrlUtils";
import { FormatPattern } from "@enums";
import { getMSALToken } from "@auth/authUtils";
export async function getPassengerCounts({ iata, from, to, granularity, utc, token, }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    params.set("from", `${format(new Date(from), FormatPattern.yyyy_MM_dd_HH_mm)}${utc ? "Z" : ""}`);
    params.set("to", `${format(new Date(to), FormatPattern.yyyy_MM_dd_HH_mm)}${utc ? "Z" : ""}`);
    params.set("interval", granularity);
    const response = await httpFlightMovementsService.get(resolveUrl({
        url: `/passenger-counts?${params}`,
        mockEndpoint: "/passengerCounts",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
export async function getPaxPredictionCounts({ iata, from, to, granularity, area, }) {
    const params = new URLSearchParams("");
    const token = await getMSALToken();
    params.set("iata", iata);
    params.set("from", format(new Date(from), FormatPattern.yyyy_MM_dd_HH_mm));
    params.set("to", format(new Date(to), FormatPattern.yyyy_MM_dd_HH_mm));
    params.set("interval", granularity);
    params.set("area", area);
    const response = await httpPaxService.get(resolveUrl({
        url: `/prediction-counts?${params}`,
        mockEndpoint: "/paxPredictionCounts",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
export async function getPaxSecurityCounts({ iata, from, to, granularity, }) {
    const token = await getMSALToken();
    const params = new URLSearchParams("");
    params.set("iata", iata);
    params.set("from", format(new Date(from), FormatPattern.yyyy_MM_dd_HH_mm));
    params.set("to", format(new Date(to), FormatPattern.yyyy_MM_dd_HH_mm));
    params.set("interval", granularity);
    const response = await httpPaxService.get(resolveUrl({
        url: `/security-count?${params}`,
        mockEndpoint: "/securityPax",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
export async function getEnroutePaxData({ iata, hoursTo = 4, }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    params.set("hoursTo", hoursTo.toString());
    const token = await getMSALToken();
    const response = await httpPaxService.get(resolveUrl({
        url: `/enroute-pax?${params}`,
        mockEndpoint: "/enroute-pax",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
export async function getPaxPredictionGraphData({ iata, date, direction, }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    params.set("date", date);
    params.set("direction", direction);
    const token = await getMSALToken();
    const response = await httpPaxService.get(resolveUrl({
        url: `/prediction-pax-graph?${params}`,
        mockEndpoint: "/prediction-pax-graph",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
export async function getPaxQueueTimes({ iata, from, to, interval, }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    params.set("from", format(new Date(from), FormatPattern.yyyy_MM_dd_HH_mm));
    params.set("to", format(new Date(to), FormatPattern.yyyy_MM_dd_HH_mm));
    params.set("interval", interval);
    const token = await getMSALToken();
    const response = await httpPaxService.get(resolveUrl({
        url: `/queue-times?${params}`,
        mockEndpoint: "/queue-times",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
