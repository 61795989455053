import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PlaneUpRightOutlined } from "@common-packages/react-icons";
import { useTranslation } from "react-i18next";
import Widget from "@components/Widget/Widget";
import { PlanOverviewAccordion } from "@features/Plan/components/PlanOverview/PlanOverviewAccordion";
import "./PlanOverviewProcessStatusesWidget.scss";
import { PlanOverviewRemarks } from "@features/Plan/components/PlanOverview/PlanOverviewRemarks";
export const PlanOverviewProcessStatusesWidget = () => {
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t("plan.create.statusWidget.title"), subtitle: t("plan.create.statusWidget.subtitle"), icon: {
                    component: (_jsx(PlaneUpRightOutlined, { width: "1.5rem", height: "1.5rem" })),
                } }), _jsx(Widget.Body, { children: _jsxs("div", { children: [_jsx("div", { className: "plan-overview-widget-remarks", children: _jsx(PlanOverviewRemarks, {}) }), _jsx(PlanOverviewAccordion, {})] }) })] }));
};
PlanOverviewProcessStatusesWidget.displayName = "PlanOverviewProcessStatusesWidget";
